export const initialState = {
  userId: '',
  accounts: [],
  privacyPolicy: null,
  rootAdmin: false,
  firstname: '',
  lastname: '',
  email: '',
  picture: '',
  identityProviders: '',
  username: '',
  permissions: {
    manageAccount: false,
    deleteAccount: false,
    manageTours: false,
    manageUsers: false,
    publishTours: false,
  },
  errorMessage: '',
};

const userCredentials = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_USER_CREDS': {
      return Object.assign({}, state, {
        userId: action.data.id,
        accounts: action.data.accounts,
        email: action.data.email,
        firstname: action.data.firstName,
        identityProviders: action.data.federatedIdentities,
        lastname: action.data.lastName,
        picture: action.data.picture,
        username: action.data.username,
        privacyPolicy: action.data.privacyPolicy,
        userCreatedDate: action.data.createdTimestamp,
      });
    }
    case 'SET_ROOT_ADMIN': {
      return Object.assign({}, state, {
        rootAdmin: true,
      });
    }
    case 'SET_USER_PERMISSIONS': {
      return {
        ...state,
        permissions: action.permissions,
      };
    }
    case 'SAVE_POLICY_STATUS': {
      return Object.assign({}, state, {
        privacyPolicy: action.privacyPolicy,
      });
    }
    default:
      return state;
  }
};

export default userCredentials;
