import React, { createContext, useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';

export const FlagContext = createContext();

const FeatureFlagProvider = ({ children, defaultFlags = {} }) => {
  const [flags, setFlags] = useLocalStorage('flags', defaultFlags);
  const setNewFlag = (flag, on = false) => {
    setFlags({
      ...flags,
      [flag]: on,
    });
  };

  const checkFlag = flag => {
    return flags[flag] || false;
  };

  useEffect(() => {
    // hiding for testing purposes
    // if (rootAdmin) {
    const params = new URLSearchParams(window.location.search);
    const queryFlags = params.getAll('flag');
    const flagObj = queryFlags?.reduce((flagObj, flag) => {
      flagObj[flag] = true;
      return flagObj;
    }, {});
    if (queryFlags.length > 0) {
      setFlags({ ...flags, ...flagObj });
    } else {
      setFlags(defaultFlags);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FlagContext.Provider value={{ checkFlag, setNewFlag }}>
      {children}
    </FlagContext.Provider>
  );
};

export default FeatureFlagProvider;
