import keycloak from '../keycloak';

export const buildAuthHeaders = accept => {
  let myHeaders = new Headers();
  const token = keycloak.token;
  if (token) {
    myHeaders.append('Authorization', `Bearer ${token}`);
  }
  accept
    ? myHeaders.append('Accept', '*/*')
    : myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};
