import { batch } from 'react-redux';

import { setPublishedTourTier } from '../api/tours';
import { handleShareError } from 'api/helpers/helpers';
import history from '../browserHistory';

import {
  publishTourToDatabase,
  fetchPublicTourFromDatabase,
} from '../api/v2/publishedTours';

import { getMedia } from './mediaActions';
import { tourPublishSuccess } from './tourActions';
import { sendHubspotEvent } from './hubspotActions';

export const savePublicTour = (tourData, callback) => {
  return dispatch => {
    publishTourToDatabase(tourData).then(res => {
      batch(() => {
        dispatch(tourPublishSuccess(tourData.id));
        dispatch(getMedia(true));
        dispatch(sendHubspotEvent({ event: 'publishNowConfirmation' }));
      });
      if (typeof callback === 'function') callback();
    });
  };
};

export const fetchPublicTour = (tourId, matchParams) => {
  return async dispatch => {
    const { lang = 'en', id, activestop } = matchParams;
    try {
      dispatch(setPublicTourStatus({ status: 'loading' }));
      const publicTour = await fetchPublicTourFromDatabase(tourId, lang);
      dispatch(savePublicTourPayload(publicTour));
    } catch (e) {
      if (e.status === 403) {
        if (e.message === 'Expired') {
          dispatch(
            setPublicTourStatus({
              status: 'error',
              errorMessage: 'Expired tour',
            })
          );
          // throw to component that made the call
          throw e;
        }
      }
      if (lang === 'en') handleShareError(e);
      else history.push(`/share/${id}/stop/${activestop}`);
    } finally {
      dispatch(setDone());
    }
  };
};

export const upgradeAllPublicTours = tier => {
  return (dispatch, getState) => {
    const allPublishedTourIds = Object.values(getState().tourData.tours)
      .filter(tour => {
        return tour.published;
      })
      .map(tour => tour.id);
    //call api to upgrade all published tour Ids
    // eslint-disable-next-line
    const successArray = Promise.all(
      allPublishedTourIds.map(async tourId => {
        return await setPublishedTourTier(tourId, tier);
      })
    );
  };
};

export const savePublicTourPayload = data => {
  const {
    aid,
    id,
    panos,
    images,
    videos,
    audio,
    stops,
    tourData,
    tier,
    active,
    analyticsMeasurementId,
    analyticsContainerId,
    captureHigherEdTrackingId,
    mapboxToken,
    customScript,
    account,
    accountPermissions,
    icons,
  } = data;
  return {
    type: 'SAVE_PUBLIC_TOUR',
    aid,
    id,
    panos,
    images,
    videos,
    audio,
    stops,
    tours: tourData,
    tier,
    active,
    analyticsMeasurementId,
    analyticsContainerId,
    captureHigherEdTrackingId,
    mapboxToken,
    customScript,
    account,
    accountPermissions,
    icons,
  };
};

export const saveNewPublishedTour = data => {
  return {
    type: 'SAVE_PUBLISHED_TOUR',
    data,
  };
};

export const showStopDescription = () => {
  return {
    type: 'SHOW_STOP_DESCRIPTION',
  };
};

export const hideStopDescription = () => {
  return {
    type: 'HIDE_STOP_DESCRIPTION',
  };
};

export const setPublicTourStatus = ({ status = '', errorMessage = '' }) => {
  return {
    type: 'PUBLIC_TOUR_STATUS',
    status,
    errorMessage,
  };
};

export const setDone = () => {
  return {
    type: 'PUBLIC_TOUR_SUCCESS',
  };
};

export const setPublicTourStopInfo = (stopName, stopNumber) => {
  return {
    type: 'PUBLIC_TOUR_STOP_INFO',
    stopName,
    stopNumber,
  };
};
