import { useEffect } from 'react';
import { initGTM } from '../utils/ga';
export const useInitGtm = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      initGTM(process.env.REACT_APP_GTM_ID);
    } else {
      console.log('GTM id not set');
    }
  }, []);
};
