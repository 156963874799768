import { buildAuthHeaders } from 'utils/buildAuthHeaders';
import { handleResponse } from 'api/helpers/helpers';

export const fetchPublicTourFromDatabase = async (id, lang = 'en') => {
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/published/${id}?language=${lang}`,
    {
      headers: myHeaders,
    }
  );
  return handleResponse(res);
};

export const publishTourToDatabase = async tour => {
  const aid = localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tour.id}/publish`,
    {
      method: 'POST',
      headers: myHeaders,
      credentials: 'include',
    }
  );
  return handleResponse(res);
};
