export const isIphone = () => navigator.appVersion.indexOf('iPhone') !== -1;

export const isChrome = () => navigator.userAgent.indexOf('Chrome') !== -1;

export const isChromeOnIOS = () => navigator.userAgent.match('CriOS');

export const isIE = () => {
  const ua = window.navigator.userAgent;
  return ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
};

export const isSafari = () =>
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1;

export async function iOSMotionEnabled() {
  var deviceorientationEvt = false;
  window.addEventListener(
    'deviceorientation',
    function(event) {
      deviceorientationEvt = event.returnValue;
    },
    true
  );

  return await new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(deviceorientationEvt);
      reject('Nah');
    }, 500)
  );
}

export const isIOS13 = () => {
  let isVersion13 = false;

  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const parsedVersion = [
      parseInt(v[1], 10),
      parseInt(v[2], 10),
      parseInt(v[3] || 0, 10),
    ];

    if (parsedVersion[0] >= 13) {
      isVersion13 = true;
    }
  }

  return isVersion13;
};
