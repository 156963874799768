import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import { handleResponse } from './helpers/helpers';

const createErrorObj = error => {
  const errorObj = new Error(error.errorMessage || '');
  errorObj.status = error.status || 400;
  throw errorObj;
};

export const getNewLinkedinAuthUrl = async () => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/linkedin`,
      {
        method: 'GET',
        headers: myHeaders,
      }
    );
    return await res.text();
  } catch (error) {
    console.log(error);
  }
};

export const postNewLinkedinUser = async data => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/linkedin`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      const error = await res.json();
      createErrorObj(error);
    }
    return await res.json();
  } catch (error) {
    throw error;
  }
};

export const getNewGoogleAuthUrl = async () => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/google`,
      {
        method: 'GET',
        headers: myHeaders,
      }
    );
    return await res.text();
  } catch (error) {
    console.log(error);
  }
};

export const postNewGoogleUser = async data => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/google`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      const error = await res.json();
      createErrorObj(error);
    }
    return await res.json();
  } catch (error) {
    throw error;
  }
};

export const postNewConcept3DUser = async data => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/concept3d`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      const error = await res.json();
      createErrorObj(error);
    }
    return await res.json();
  } catch (error) {
    throw error;
  }
};

export const postNewUser = async userData => {
  let myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(userData),
      }
    );
    if (!res.ok) {
      const error = await res.json();
      createErrorObj(error);
    }
    return await res.json();
  } catch (error) {
    throw error;
  }
};

export const updateUser = async userData => {
  let myHeaders = buildAuthHeaders();
  const uid = localStorage.getItem('uid');
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/${uid}`,
    {
      method: 'PUT',
      credentials: 'include',
      headers: myHeaders,
      body: JSON.stringify(userData),
    }
  );

  if (res.status >= 400) {
    const keys = Object.keys(userData).join(', ');
    throw new Error(
      res.status === 409
        ? `Failed to update ${keys}. Already in use.`
        : `Error updating ${keys}. Wait and try again later.`
    );
  }
  return res.status;
};

export const updateC3DUserPrivacyPolicy = userData => {
  let myHeaders = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/users/privacy`,
    {
      method: 'PATCH',
      credentials: 'include',
      headers: myHeaders,
      body: JSON.stringify(userData),
    }
  )
    .then(res => {
      return res.json();
    })
    .then(data => {
      const { privacyPolicy, uid } = data;
      const userData = {
        privacyPolicy,
        uid,
      };
      return userData;
    });
};

export const fetchUserFromC3D = userId => {
  let myHeaders = buildAuthHeaders();
  const uid = userId || localStorage.getItem('uid');

  return fetch(
    `${
      process.env.REACT_APP_ACCOUNTS_API
    }/api/admin/v2/users/${encodeURIComponent(uid)}`,
    { headers: myHeaders, credentials: 'include' }
  )
    .then(res => {
      if (res.status >= 400) {
        throw new Error('Failed fetching user');
      }
      return res.json();
    })
    .then(data => {
      return data;
    });
};

export const checkIfEmailExistsInC3D = email => {
  let myHeaders = buildAuthHeaders();
  return fetch(
    `${
      process.env.REACT_APP_ACCOUNTS_API
    }/api/admin/users/email/${encodeURIComponent(email)}`,
    { headers: myHeaders }
  )
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    });
};

export const changeUserPassword = async password => {
  let myHeaders = buildAuthHeaders();
  const uid = localStorage.getItem('uid');
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/${uid}/password`,
    {
      method: 'PUT',
      headers: myHeaders,
      credentials: 'include',
      body: JSON.stringify({
        password,
      }),
    }
  );
  if (res.status >= 400) {
    throw new Error('Failed to change password');
  }
  return await res.text();
};

export const sendPasswordReset = async uid => {
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/${uid}/sendPasswordReset`,
    {
      method: 'PUT',
      headers: myHeaders,
      credentials: 'include',
    }
  );
  return await handleResponse(res);
};

export const unlinkIdentityProvider = async federatedIdentity => {
  let myHeaders = buildAuthHeaders();
  const uid = localStorage.getItem('uid');
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/${uid}/federated-identity`,
    {
      method: 'DELETE',
      headers: myHeaders,
      credentials: 'include',
      body: JSON.stringify({
        federatedIdentity,
      }),
    }
  );
  if (res.status >= 400) {
    throw new Error(`Failed to unlink ${federatedIdentity} account`);
  }
  return await res.text();
};

// account user routes

// need: password reset, enable user?
export const getUserRolePermissions = async () => {
  const myHeaders = buildAuthHeaders();
  const uid = localStorage.getItem('uid');
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/users/${uid}/roles`,
    {
      method: 'GET',
      headers: myHeaders,
      credentials: 'include',
    }
  );
  return await handleResponse(res);
};
