/* CacheBuster component */
import { useEffect, useState } from 'react';
import pkgFile from '../../package.json';
global.appVersion = pkgFile.version;

const semverGreaterThan = (versionA = '', versionB = '') => {
  // prevents looping if meta doesn't return a version
  if (!versionA || !versionB) return false;
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const useCacheBuster = () => {
  const [{ loading, isUpToDate }, setNewState] = useState({
    loading: true,
    isUpToDate: true,
  });

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and reloading...');
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(async name => {
          await caches.delete(name);
        });
      });
    }
    // delete browser cache and hard reload - firefox needs true parameter https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    window.location.reload(true);
  };

  const checkVersions = async (initialLoad = false) => {
    // to test locally create a meta.json file in the public folder
    // ex. {"version":"2.1.0"} to some version older than current
    try {
      const response = await fetch('/meta.json', { cache: 'no-store' });
      const meta = await response.json();
      const latestVersion = meta?.version;
      const currentVersion = global.appVersion;
      const shouldForceRefresh = semverGreaterThan(
        latestVersion,
        currentVersion
      );
      console.log('Current Version', currentVersion);
      if (shouldForceRefresh) {
        setNewState({ loading: false, isUpToDate: false });
        initialLoad && refreshCacheAndReload();
      } else {
        setNewState({ loading: false, isUpToDate: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkVersions(true);
    setInterval(() => {
      checkVersions();
    }, 5 * 60 * 1000); // 5 min poll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, isUpToDate, refreshCacheAndReload };
};

export default useCacheBuster;
