import React from 'react';
import cx from 'classnames';
import './Button.scss';

const Button = ({
  children,
  className,
  handleClick,
  active,
  myRef,
  ...rest
}) => (
  <button
    className={cx(className, { active })}
    onClick={handleClick}
    ref={myRef}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
