export const returnMediaType = (mediaType, lowercase = false) => {
  const descriptiveMedia = {
    panos: 'Panorama',
    images: 'Image',
    icons: 'Icon',
    videos: 'Video',
    audio: 'Audio',
    videos360: '360º Video',
    logo: 'Logo',
  };

  let mediaDescription = descriptiveMedia[mediaType];
  let returnedDescription;

  if (lowercase) {
    // media description is lowercase
    returnedDescription = mediaDescription.toLowerCase();
  } else {
    // first letter is already uppercased, just return
    returnedDescription = mediaDescription;
  }
  return returnedDescription;
};
