import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

// language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
// if you're using a language detector, do not define the lng option
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
