export const initialState = { show: 'map' };

const miniMap = (state = initialState, action) => {
  switch (action.type) {
    case 'SWITCH_MAP_VIEW':
      return Object.assign({}, state, { show: action.show });
    default:
      return state;
  }
};

export default miniMap;
