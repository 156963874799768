import React from 'react';
import Helmet from 'react-helmet';

const Favicon = ({ faviconId }) => (
  <React.Fragment>
    {faviconId ? (
      <Helmet
        link={[
          {
            href: `https://res.cloudinary.com/concept3d/image/upload/q_auto,f_auto,dpr_1.0,h_32,w_32/v1/images/${faviconId}`,
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
          },
          {
            href: `https://res.cloudinary.com/concept3d/image/upload/q_auto,f_auto,dpr_2.0,h_180,w_180/v1/images/${faviconId}`,
            rel: 'apple-touch-icon',
            type: 'image/png',
            sizes: '180x180',
          },
          {
            href: `https://res.cloudinary.com/concept3d/image/upload/q_auto,f_auto,dpr_1.0,h_192,w_192/v1/images/${faviconId}`,
            rel: 'icon',
            type: 'image/png',
            sizes: '192x192',
          },
        ]}
      />
    ) : (
      <Helmet
        link={[
          {
            href: '/favicon_32.png',
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
          },
          {
            href: '/favicon_180.png',
            rel: 'apple-touch-icon',
            type: 'image/png',
            sizes: '180x180',
          },
          {
            href: '/favicon_192.png',
            rel: 'icon',
            type: 'image/png',
            sizes: '192x192',
          },
        ]}
      />
    )}
  </React.Fragment>
);

export default Favicon;
