export const initialState = {
  hasEdits: false,
};

function config(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_EDIT_CHANGES':
      return Object.assign({}, state, {
        hasEdits: action.value,
      });
    default:
      return state;
  }
}

export default config;
