import * as stopsApi from 'api/v2/stops';
import { batch } from 'react-redux';
import { setTourUpToDate, addStopToTour } from './tourActions';

export const getStopsByTour = tourId => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const stops = await stopsApi.getStopsByTour(tourId);
      stops.forEach(stop => {
        dispatch(saveStop(stop));
      });
      dispatch(setDone());
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong fetching stops.')
      );
    }
  };
};

export const getStop = stopId => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const stop = await stopsApi.getStop(stopId);
      batch(() => {
        dispatch(saveStop(stop));
        dispatch(setDone());
      });
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong fetching the stop.')
      );
    }
  };
};

export const postStop = (stopData, callback) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const { stop, tourId } = await stopsApi.postStop(stopData);
      batch(() => {
        dispatch(saveStop(stop));
        dispatch(addStopToTour(tourId, stop.id));
        dispatch(setTourUpToDate(tourId, false));
        dispatch(setDone());
      });
      typeof callback === 'function' && callback();
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong saving the stop.')
      );
    }
  };
};

export const updateStop = (stopData, tourId, callback) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const stop = await stopsApi.updateStop(stopData, tourId);
      batch(() => {
        dispatch(saveStop(stop));
        dispatch(setTourUpToDate(tourId), false);
        dispatch(setDone());
      });
      typeof callback === 'function' && callback();
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong saving the stop.')
      );
    }
  };
};

export const deleteStop = (stopId, tourId) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const id = await stopsApi.deleteStop(stopId, tourId);
      batch(() => {
        dispatch(deleteByStopId(id));
        dispatch(setTourUpToDate(tourId), false);
        dispatch(setDone());
      });
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong deleting the stop.')
      );
    }
  };
};

export const saveStop = stop => {
  return {
    type: 'SAVE_STOP',
    stop,
  };
};

export const deleteByStopId = stopId => {
  return {
    type: 'DELETE_STOP',
    stopId,
  };
};

export const setSaving = () => {
  return {
    type: 'STOPS_STATUS',
    status: 'saving',
    errorMessage: '',
  };
};

export const setDone = () => {
  return {
    type: 'STOPS_STATUS',
    status: 'ok',
    errorMessage: '',
  };
};

export const setError = errorMessage => {
  return {
    type: 'STOPS_STATUS',
    status: 'error',
    errorMessage,
  };
};
