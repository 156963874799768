import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMedia } from 'actions/mediaActions';

const useFetchMedia = () => {
  const { media } = useSelector(state => state);
  const dispatch = useDispatch();

  const { mediaFetched, mediaFetching } = media;

  useEffect(() => {
    if (!mediaFetched && mediaFetching === 'fetch_success') {
      dispatch(getMedia(true));
    }
  }, [dispatch, mediaFetched, mediaFetching]);

  return media;
};

export default useFetchMedia;
