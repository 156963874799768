import * as api from '../api/session';

let interval;

export const pollSessionData = (time = 120) => {
  return async dispatch => {
    if (interval) clearInterval(interval);
    dispatch(getAccountSessions());
    interval = setInterval(() => {
      dispatch(getAccountSessions());
    }, time * 1000);
  };
};

export const getAccountSessions = () => {
  return async dispatch => {
    const sessions = await api.getAccountSessions();
    dispatch(saveActiveSessions(sessions));
  };
};

export const saveActiveSessions = data => {
  return {
    type: 'SAVE_ACTIVE_SESSIONS',
    data,
  };
};
