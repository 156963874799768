export const initialState = {
  userMenuOpen: false,
  helpMenuOpen: false,
  publishQueueOpen: false,
  activeUsersOpen: false,
};

function header(state = initialState, action = {}) {
  switch (action.type) {
    case 'TOGGLE_HEADER_DROPDOWNS':
      return { ...state, ...action.newDropDownState };
    default:
      return state;
  }
}

export default header;
