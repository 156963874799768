export const initialState = {
  state: 'ok',
  error: {},
};

const hotspots = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUBMIT_HOTSPOT':
      return {
        ...state,
        state: 'saving',
      };
    case 'SUBMIT_HOTSPOT_ERROR':
      return {
        ...state,
        state: 'error',
        error: action.error,
      };
    case 'SUBMIT_HOTSPOT_SUCCESS':
      return {
        ...state,
        state: 'ok',
        error: {},
      };
    default:
      return state;
  }
};

export default hotspots;
