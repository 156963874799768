import { buildAuthHeaders } from '../utils/buildAuthHeaders';

export const updateStripePaymentSource = async data => {
  let myHeaders = buildAuthHeaders();
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_ACCOUNTS_API
      }/api/admin/accounts/tour/${encodeURIComponent(
        data.userId
      )}/source/update`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    const json = await response.json();
    if (json.errorMessage) {
      return json.errorMessage;
    }
    return json;
  } catch (err) {
    return err;
  }
};

export const newPaymentSource = async data => {
  let myHeaders = buildAuthHeaders();
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_ACCOUNTS_API
      }/api/admin/accounts/tour/${encodeURIComponent(data.userId)}/source/new`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    const json = await response.json();
    if (json.errorMessage) {
      return json.errorMessage;
    }
    return json;
  } catch (err) {
    return err;
  }
};

export const stripePaymentHistory = async stripeId => {
  let myHeaders = buildAuthHeaders();
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_ACCOUNTS_API
      }/api/admin/accounts/tour/payments/${encodeURIComponent(stripeId)}`,
      { headers: myHeaders, credentials: 'include' }
    );
    return await response.json();
  } catch (err) {
    return err;
  }
};
