import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const useConcept3dLogin = ({
  onSuccess = () => {},
  onFailure = () => {},
  isCallback,
}) => {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    if (isCallback && initialized && !!keycloak.authenticated) {
      onSuccess();
      localStorage.removeItem('tb_signup');
    }
  }, [
    initialized,
    isCallback,
    keycloak.authenticated,
    keycloak.token,
    onSuccess,
  ]);

  async function signIn(e) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    const redirectUri = `${window.origin}/tos/concept3d/callback`;
    keycloak.login({ redirectUri });
  }

  return { signIn };
};

export default useConcept3dLogin;
