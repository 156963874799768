import { useEffect, useState } from 'react';
import { getCloudinaryBlob } from '../api/hotspots';
import cat404 from '../images/cat-404.jpg';

export const useGetCloudinaryBlob = url => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    const craftSrc = async () => {
      try {
        const response = await getCloudinaryBlob(url);
        if (response) {
          return response;
        }
      } catch (e) {
        return cat404;
      }
    };
    if (url) {
      // res is returned as a blob() which requires us to read the stream with createObjectURL
      craftSrc(url).then(
        res => !isCancelled && setThumbnailUrl(URL.createObjectURL(res))
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [url]);

  return thumbnailUrl;
};
