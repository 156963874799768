import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Routes from './routes';
import './i18n';
import CacheBuster from 'components/CacheBuster/CacheBuster';
import * as serviceWorker from './serviceWorker';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import FeatureFlagProvider from 'featureFlags/FeatureFlagProvider';

import keycloak from './keycloak';

global.embedpano = window.embedpano;

export const defaultFlags = {
  'multi-user': false,
  revisions: false,
  'out-of-world': true,
  'multi-lang': true,
  'multi-lang-v2': false,
};

const app = (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      checkLoginIframe: false,
      onLoad: 'check-sso',
    }}
  >
    <FeatureFlagProvider defaultFlags={defaultFlags}>
      <CacheBuster />
      <Routes />
    </FeatureFlagProvider>
  </ReactKeycloakProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();
