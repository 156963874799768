import React, { memo, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import AuthCallback from '../Auth/AuthCallback/AuthCallback';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSearchParam, searchParams } from 'utils/searchParams';
import { isMobile } from 'react-device-detect';
import { handleUserSignIn } from 'actions/signInActions';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const [isFetched, setIsFetched] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();

  useEffect(() => {
    if (
      initialized &&
      !keycloak.authenticated &&
      location.pathname.includes('/home')
    ) {
      keycloak.login();
    }
  }, [initialized, keycloak, location]);

  useEffect(() => {
    if (
      !isFetched &&
      location.pathname.includes('/home') &&
      keycloak.authenticated
    ) {
      setIsFetched(true);
      const { email, groups, sub: uid } = keycloak.tokenParsed;
      const aidQueryParam = getSearchParam(searchParams.ACCOUNT_ID);
      const tourGroups = groups.filter(user => user.includes('/tours/'));
      if (
        !aidQueryParam &&
        !isMobile &&
        tourGroups.length > 1 &&
        localStorage.getItem('selectedAccount') !== 'true'
      ) {
        // if the user has access to more than one account send here
        localStorage.setItem('selectedAccount', 'true');
        history.push('/home/accounts');
      }

      const privacyCallback = () => {
        history.push('/home/privacy');
      };

      if (tourGroups.length < 1) {
        history.push({
          pathname: '/tos/concept3d',
          state: {
            hasC3DAccount: true,
            email,
          },
        });
      } else {
        try {
          dispatch(handleUserSignIn(uid, aidQueryParam, privacyCallback));
        } catch (error) {
          history.push('/error/acountnotfound');
        }
      }
    }
  }, [location, dispatch, history, keycloak, isFetched]);

  return (
    <Route
      {...rest}
      render={props => (
        <>
          {!!keycloak.authenticated && (
            <Component keycloak={keycloak} {...props} />
          )}
          <AuthCallback fadeout={!!keycloak.authenticated} />
        </>
      )}
    />
  );
};

export default memo(PrivateRoute);
