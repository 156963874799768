import React from 'react';
import LoadingSvg from '../../SvgComponents/LoadingSvg';
import './LoadingComponent.scss';

const LoadingComponent = ({ error, pastDelay }) => {
  if (pastDelay) {
    return (
      <div id="async-loading">
        <LoadingSvg />
      </div>
    );
  } else if (error) {
    console.log(error);
    return (
      <div id="async-error">
        {console.log(error)}
        <p className="async-error-text">
          Sorry, there was a problem loading the page. Please refresh your
          browser
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingComponent;
