import omit from 'lodash.omit';

export const initialState = {
  stops: {},
  status: '',
  errorMessage: '',
};

const stops = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_STOP': {
      return {
        ...state,
        stops: {
          ...state.stops,
          [action.stop.id]: {
            ...state.stops[action.stop.id],
            ...action.stop,
          },
        },
      };
    }
    case 'DELETE_STOP': {
      return {
        ...state,
        stops: omit(state.stops, action.stopId),
      };
    }
    case 'STOPS_STATUS': {
      return {
        ...state,
        status: action.status,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default stops;
