import { sendPasswordReset } from '../api/users';
import * as api from '../api/accountUsers';
import {
  selectRole,
  selectUserAssignments,
  selectAccountOwnerUid,
} from '../selectors/accountUsersSelector';
import { saveUserPermissions } from './signInActions';
import { selectCurrentUserId } from './../selectors/userSelector';
import { setTBError } from './errorsActions';

const genericError = 'Something went wrong! Please try again';

export const getUsersByAccount = () => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const users = await api.getLinkedUsers();
      users.forEach(user => {
        dispatch(saveAccountUser(user));
      });
      dispatch(setDone());
    } catch (error) {
      dispatch(setError(error.errorMessage || genericError));
    }
  };
};

export const getRolesByAccount = () => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const {
        roles = [],
        assignments = [],
      } = await api.getAccountRolesAndAssigments();

      roles.forEach(role => {
        dispatch(saveAccountRole(role));
      });

      assignments.forEach(assignment => {
        dispatch(saveAccountAssignment(assignment));
      });
    } catch (error) {
      dispatch(setError(error.errorMessage || genericError));
    }
  };
};

export const createAccountUser = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSaving());
      await api.postLinkedUser(data);
      dispatch(setDone());
      typeof callback === 'function' && callback();

      dispatch(getUsersByAccount());
      dispatch(getRolesByAccount());
    } catch (error) {
      const message = error.errorMessage || genericError;
      dispatch(setError(message, error.status));
    }
  };
};

export const updateAccountUser = (id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSaving());
      await api.updateLinkedUser(id, data);
      dispatch(setDone());
      typeof callback === 'function' && callback();
      dispatch(getUsersByAccount());
      dispatch(getRolesByAccount());
    } catch (error) {
      const message = error.errorMessage || genericError;
      dispatch(setError(message, error.status));
    }
  };
};

export const deleteAccountUser = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSaving());
      await api.deleteLinkedUser(id);
      dispatch(deleteByUserId(id));
      dispatch(setDone());
      typeof callback === 'function' && callback();
    } catch (error) {
      const message = error.errorMessage || genericError;
      dispatch(setError(message, error.status));
    }
  };
};

export const resetPassword = (id, callback) => {
  return async dispatch => {
    try {
      dispatch(setSaving());
      await sendPasswordReset(id);
      dispatch(setDone());
    } catch (error) {
      const message = error.errorMessage || genericError;
      dispatch(setTBError(message, error.status));
    } finally {
      typeof callback === 'function' && callback();
    }
  };
};

export const updateAccountOwner = (newOwnerId, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSaving());
      const state = getState();
      const oldOwnerId = selectAccountOwnerUid(state);
      const currentUserId = selectCurrentUserId(state);
      await api.updateAccountOwner(newOwnerId, oldOwnerId);

      if (currentUserId === oldOwnerId) {
        setNewUserPermissions(dispatch, state);
      }
      const newAssignments = getNewAssignments(state, newOwnerId, oldOwnerId);
      dispatch(setNewUserAssignments(newAssignments));
    } catch (error) {
      const message = error.errorMessage || genericError;
      dispatch(setTBError(message, error.status));
    } finally {
      dispatch(setDone());
      typeof callback === 'function' && callback();
    }
  };
};

export const setNewUserPermissions = (dispatch, state) => {
  const adminPermissions = selectRole(state, 'admin').permissions;
  dispatch(saveUserPermissions(adminPermissions));
};

const getNewAssignments = (state, newOwnerId, oldOwnerId) => {
  const newOwnerAssign = selectUserAssignments(state, newOwnerId);
  const oldOwnerAssign = selectUserAssignments(state, oldOwnerId);
  const ownerRoleId = selectRole(state, 'owner').rid;
  const adminRoleId = selectRole(state, 'admin').rid;

  return {
    [newOwnerId]: {
      ...newOwnerAssign,
      rid: ownerRoleId,
    },
    [oldOwnerId]: {
      ...oldOwnerAssign,
      rid: adminRoleId,
    },
  };
};

export const saveAccountUser = user => {
  return {
    type: 'SAVE_ACCOUNT_USER',
    user,
  };
};

export const setNewUserAssignments = newAssignments => {
  return {
    type: 'UPDATE_USER_ASSIGNMENTS',
    newAssignments,
  };
};

export const saveAccountRole = role => {
  return {
    type: 'SAVE_ACCOUNT_ROLE',
    role,
  };
};

export const saveAccountAssignment = assignment => {
  return {
    type: 'SAVE_ACCOUNT_ASSIGNMENT',
    assignment,
  };
};

export const deleteByUserId = id => {
  return {
    type: 'DELETE_ACCOUNT_USER',
    id,
  };
};

export const resetAccountUserData = () => {
  return {
    type: 'RESET_ACCOUNT_USERS',
  };
};

export const setSaving = () => {
  return {
    type: 'ACCOUNT_USERS_STATUS',
    status: 'saving',
    errorMessage: '',
    errorCode: '',
  };
};

export const setDone = () => {
  return {
    type: 'ACCOUNT_USERS_STATUS',
    status: 'ok',
    errorMessage: '',
    errorCode: '',
  };
};

export const setError = (errorMessage, errorCode) => {
  return {
    type: 'ACCOUNT_USERS_STATUS',
    status: 'error',
    errorMessage,
    errorCode,
  };
};
