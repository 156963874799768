const gaConsent = 'ga_consent';

export const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export const getItem = (key) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
}

export const saveConsentToLocalStorage = consent => {
  setItem(gaConsent, JSON.stringify(consent));
}

export const getConsentFromLocalStorage = () => {
  const consent = getItem(gaConsent);
  return consent ? JSON.parse(consent) : null;
}