import cx from 'classnames';
import { useCacheBuster } from 'hooks';
import Button from 'components/Buttons/Button';
import './ResetCache.scss';

const ResetCache = ({ children, buttonCopy = '', errorPage = false }) => {
  const { refreshCacheAndReload } = useCacheBuster();
  const wrapperClasses = cx('reset-cache', {
    bottom: errorPage,
  });

  function clickHandler() {
    refreshCacheAndReload(errorPage);
  }

  return (
    <section className={wrapperClasses}>
      {errorPage && 'Here by mistake? Try'}
      <Button className="red-btn" handleClick={clickHandler}>
        {buttonCopy || 'Refresh and Reload'}
      </Button>
    </section>
  );
};

export default ResetCache;
