import { buildAuthHeaders } from '../../utils/buildAuthHeaders';
import { handleResponse } from 'api/helpers/helpers';

export const getToursByAid = async ({ aid = localStorage.getItem('aid') }) => {
  const myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours`,
      {
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const getTour = async tid => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tid}`,
      {
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const postTour = async data => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const updateTour = async data => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${data.id}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );

    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const deleteTour = async tid => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tid}`,
      {
        method: 'DELETE',
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const deleteTourStops = async (tid, data) => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tid}/stops`,
      {
        method: 'DELETE',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const revertTourChanges = async tourId => {
  const aid = localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tourId}/unpublish`,
    {
      method: 'POST',
      headers: myHeaders,
      credentials: 'include',
    }
  );

  return await handleResponse(res);
};
