export const searchParams = {
  COOKIE_POLICY_HIDE: 'cph', // matching atlas fe
  GTAG_CONSENT: 'gtagConsent',
  MAP_VIEW: 'mv',
  ACCOUNT_ID: 'aid',
};

export const gtagConsentParamValues = {
  GRANTED: 'granted',
  DENIED: 'denied',
  NECESSARY: 'necessary',
};

const getSearchParams = () => new URLSearchParams(window.location.search);

export const getSearchParam = param => {
  const params = getSearchParams();
  return params.get(param);
};

export const hasSearchParam = param => {
  const params = getSearchParams();
  return params.has(param);
};