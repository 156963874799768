import keycloak from 'keycloak';
import history from '../../browserHistory';

export const handleResponse = async (res, noAuth) => {
  const type = res.headers.get('Content-Type');
  // can bypass this check
  !noAuth && handleUnnauthorized(res, keycloak);
  const result =
    type && type.includes('application/json')
      ? await res.json()
      : await res.text();
  if (!res.ok) throw result;
  return result;
};

export const handleUnnauthorized = async ({ status }, _keycloak = {}) => {
  if (status === 401) {
    if (_keycloak.authenticated) {
      history.push('/error/unauthorized');
    } else {
      _keycloak.login();
    }
  }
};

export const handleShareError = async ({ status, ok }) => {
  if (!ok && status !== 401) {
    history.push(`/share/error/${status}`);
  }
};

export const fetchFileSize = async (fileUrl, callback) => {
  try {
    const res = await fetch(fileUrl, { method: 'HEAD' });
    const contentLength = res.headers.get('Content-Length');
    typeof callback === 'function' && callback(contentLength);
    return contentLength;
  } catch (error) {
    console.error(error);
  }
};
