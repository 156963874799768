import { removeTourFromSession, updateSessionTour } from 'api/session';
import { usePrevious } from 'hooks';
import { useEffect } from 'react';

const TourSession = ({ match }) => {
  const prevTour = usePrevious(match.params.tid);
  useEffect(() => {
    if (prevTour !== match.params.tid) {
      prevTour && removeTourFromSession(prevTour);
      updateSessionTour(match.params.tid);
    }
  }, [match.params.tid, prevTour]);
  useEffect(() => {
    return () => {
      removeTourFromSession(match.params.tid);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default TourSession;
