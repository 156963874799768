export const initialState = {
  showSnackBar: false,
  message: '',
};

const snackBar = (state = initialState, action) => {
  switch (action.type) {
    case 'POPULATE_SNACKBAR':
      return { ...state, ...action.snackBarData };
    case 'CLEAR_SNACKBAR':
      return { ...state, ...{ showSnackBar: false } };
    default:
      return state;
  }
};

export default snackBar;
