export const initialState = {
  state: 'ok',
  error: {},
};

const forms = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_TOUR_FORM_OPERATION':
      return {
        ...state,
        state: 'ok',
        error: {},
      };
    case 'ERROR_TOUR_FORM_OPERATION':
      return {
        ...state,
        state: 'error',
        error: action.error,
      };
    default:
      return state;
  }
};

export default forms;
