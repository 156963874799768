export const initialState = {
  activeUsers: {},
  activeTours: {},
};

const sessions = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_ACTIVE_SESSIONS': {
      const activeData = action.data.reduce(
        (acc, user) => {
          if (user?.activeTours && user.activeTours.length > 0) {
            user.activeTours.forEach(tourId => {
              const activeTour = acc.activeTours[tourId] || [];
              acc.activeTours[tourId] = [...activeTour, user.uid];
            });
          }
          if (!acc.activeUsers[user.uid]?.active) {
            // don't override an active user. in case they left another window open
            acc.activeUsers[user.uid] = user;
          }
          return acc;
        },
        { activeTours: {}, activeUsers: {} }
      );
      // overwriting this everytime
      return activeData;
    }
    default:
      return state;
  }
};

export default sessions;
