import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const accessMessage = {
  manageTours: 'manage tours',
  publishTours: 'publish tours',
  manageUsers: 'manage users',
  deleteAccount: 'delete the account',
  manageAccount: 'manage the account',
  viewTour: 'view restricted tours',
};

const useCheckPermission = (permission = '') => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const { permissions = [], rootAdmin } = useSelector(
    ({ userCredentials }) => userCredentials || {}
  );

  useEffect(() => {
    setPermissionGranted(rootAdmin || permissions[permission] || false);
  }, [rootAdmin, permission, permissions]);

  const sorryMessage = !permissionGranted
    ? `Sorry! You do not have access to ${accessMessage[permission]}. Contact your tour admin to make changes here.`
    : '';

  return [permissionGranted, sorryMessage];
};

export default useCheckPermission;
