import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import { returnMediaType } from '../utils/returnMediaType';
import { handleResponse } from './helpers/helpers';

export const getMediaFromDatabase = (aid, showTourData) => {
  const myHeaders = buildAuthHeaders();
  // should only use tour_data in the media library
  const addQueryString = showTourData ? '?tour_data=true' : '';
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/media/${aid}${addQueryString}`,
    {
      method: 'GET',
      headers: myHeaders,
      credentials: 'include',
    }
  )
    .then(res => {
      // handles 404
      if (res.status !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: 'Error Fetching Media Data',
        };
      }
      return res.json();
    })
    .then(mediaData => {
      return mediaData;
    });
};

export const saveMediaToDatabase = mediaData => {
  const myHeaders = buildAuthHeaders();
  const mediaType = returnMediaType(mediaData.type);

  return fetch(`${process.env.REACT_APP_TOUR_API}/media`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(mediaData),
    credentials: 'include',
  })
    .then(res => {
      // handles 404
      if (res.status !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: `${mediaType} failed to upload. ${
            mediaType === 'Video'
              ? 'Check that the URL is correct and try again.'
              : 'Please try again.'
          }`,
          status: res.status,
        };
      }
      return res.json();
    })
    .then(mediaData => {
      return mediaData;
    });
};

export const updateMediaDatabase = mediaData => {
  const myHeaders = buildAuthHeaders();

  return fetch(`${process.env.REACT_APP_TOUR_API}/media`, {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(mediaData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(mediaData => {
      return mediaData;
    });
};

export const savePanoUrlToDatabase = panoData => {
  const myHeaders = buildAuthHeaders();

  return fetch(`${process.env.REACT_APP_TOUR_API}/panos`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(panoData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(panoData => {
      return panoData;
    });
};

export const triggerKRPanoBuild = panoData => {
  const myHeaders = buildAuthHeaders();

  return fetch(`${process.env.REACT_APP_ACCOUNTS_API}/buildpano`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(panoData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(panoData => {
      return panoData;
    });
};

export const updatePanoDatabase = panoData => {
  const myHeaders = buildAuthHeaders();

  return fetch(`${process.env.REACT_APP_TOUR_API}/panos`, {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(panoData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(panoData => {
      return panoData;
    });
};

export const deletePanoFromDatabase = panoData => {
  const myHeaders = buildAuthHeaders();

  return fetch(`${process.env.REACT_APP_TOUR_API}/panos`, {
    method: 'DELETE',
    headers: myHeaders,
    body: JSON.stringify(panoData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(panoData => {
      return panoData;
    });
};

export const deleteMediaFromDatabase = ({ aid, id, type }) => {
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/media/${aid}/assets/${id}`, {
    method: 'DELETE',
    headers: myHeaders,
    body: JSON.stringify({ type }),
    credentials: 'include',
  })
    .then(res => {
      return res;
    })
    .then(data => {
      return data.status;
    })
    .catch(e => console.log('ERROR DELETING MEDIA', e));
};

export const searchMedia = async (aid, type, search) => {
  try {
    let myHeaders = buildAuthHeaders();
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/media/${aid}?type=${type}&search=${search}&tour_data=true`,
      {
        method: 'GET',
        headers: myHeaders,
        credentials: 'include',
      }
    );

    return await handleResponse(res);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
