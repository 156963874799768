import React from 'react';

const LoadingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.25 59.3"
    className="c3d-loader"
  >
    <g transform="translate(-0.025)">
      <path
        className="loader-2"
        d="M73,27.3l-2-1.2-27.5,16a12.524,12.524,0,0,1-12.6,0L3.3,26.2l-2,1.2a2.623,2.623,0,0,0,0,4.5L31.6,49.4a11.2,11.2,0,0,0,11.2,0L73,31.8A2.623,2.623,0,0,0,73,27.3Z"
      />
      <path
        className="loader-3"
        d="M73,35.8l-2-1.2L43.5,50.5a12.524,12.524,0,0,1-12.6,0L3.4,34.6,1.3,35.8a2.623,2.623,0,0,0,0,4.5L31.6,57.8a11.2,11.2,0,0,0,11.2,0L73,40.2A2.536,2.536,0,0,0,73,35.8Z"
      />{' '}
      <path
        className="loader-1"
        d="M1.3,23.4,31.6,40.9a11.2,11.2,0,0,0,11.2,0L73,23.4a2.623,2.623,0,0,0,0-4.5L42.8,1.5A11.477,11.477,0,0,0,37.2,0a11.2,11.2,0,0,0-5.6,1.5L1.3,19A2.536,2.536,0,0,0,1.3,23.4Z"
      />
      <path
        className="loader-1-inner"
        d="M14,23.9,32.1,34.4a10.265,10.265,0,0,0,10,0L60.2,23.9a3,3,0,0,0,0-5.2L53.7,15l-5.9,3.5,4.9,2.8L39.1,29.2a4.072,4.072,0,0,1-4,0L21.5,21.3l13.6-7.9a4.072,4.072,0,0,1,4,0l3.3,1.9,5.9-3.5L42.2,8.3a9.934,9.934,0,0,0-10,0L14,18.7a2.984,2.984,0,0,0-1.5,2.6A2.794,2.794,0,0,0,14,23.9Z"
      />
    </g>
  </svg>
);

export default LoadingSvg;
