export const initialState = {
  aid: null,
  tourId: null,
  panos: null,
  images: null,
  videos: null,
  videos360: null,
  audio: null,
  tours: null,
  email: null,
  tier: null,
  active: true,
  analyticsMeasurementId: null,
  analyticsContainerId: null,
  captureHigherEdTrackingId: null,
  mapboxToken: null,
  customScript: null,
  stopDescriptionMinimized: true,
  status: '',
  errorMessage: '',
  currentStopName: null,
  currentStopNumber: -1,
};

function publicTour(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_PUBLIC_TOUR':
      return Object.assign({}, state, {
        aid: action.aid,
        tourId: action.id,
        panos: action.panos,
        videos: action.videos,
        videos360: action.videos360,
        images: action.images,
        audio: action.audio,
        stops: action.stops,
        tier: action.tier,
        tours: action.tours,
        active: action.active,
        analyticsMeasurementId: action.analyticsMeasurementId,
        analyticsContainerId: action.analyticsContainerId,
        captureHigherEdTrackingId: action.captureHigherEdTrackingId,
        mapboxToken: action.mapboxToken,
        customScript: action.customScript,
        accountPermissions: action.accountPermissions,
        icons: action.icons,
      });
    case 'SHOW_STOP_DESCRIPTION': {
      return {
        ...state,
        stopDescriptionMinimized: false,
      };
    }
    case 'HIDE_STOP_DESCRIPTION': {
      return {
        ...state,
        stopDescriptionMinimized: true,
      };
    }
    case 'PUBLIC_TOUR_STATUS': {
      return {
        ...state,
        status: action.status,
        errorMessage: action.errorMessage,
      };
    }
    case 'PUBLIC_TOUR_SUCCESS':
      return {
        ...state,
        status: 'ok',
        error: {},
      };
    case 'PUBLIC_TOUR_STOP_INFO':
      return {
        ...state,
        currentStopNumber: action.stopNumber,
        currentStopName: action.stopName,
      };
    default:
      return state;
  }
}

export default publicTour;
