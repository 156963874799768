const initialState = {
  state: 'ok',
  error: {},
};

const hubspot = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUBMIT_HUBSPOT_EVENT':
      return {
        ...state,
        state: 'sending',
      };
    case 'SUBMIT_HUBSPOT_ERROR':
      return {
        ...state,
        state: 'error',
        error: action.error,
      };
    case 'SUBMIT_HUBSPOT_SUCCESS':
      return {
        ...state,
        state: 'ok',
        error: {},
      };
    default:
      return state;
  }
};

export default hubspot;
