import { useEffect } from 'react';
import { getNewLinkedinAuthUrl } from 'api/users';

const useLinkedinLogin = ({
  onSuccess = () => {},
  onFailure = () => {},
  isCallback,
}) => {
  useEffect(() => {
    if (isCallback) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');

      let newURL = window.location.pathname;
      urlParams.delete('state');
      urlParams.delete('error');
      urlParams.delete('error_description');
      urlParams.delete('code');

      if (urlParams.toString()) {
        newURL = newURL + '?' + urlParams.toString();
      }

      window.history.replaceState(null, null, newURL);

      if (error) {
        onFailure(error);
      } else if (code) {
        onSuccess(code);
      }
    }
  }, [isCallback, onFailure, onSuccess]);

  async function signIn(e) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    const url = await getNewLinkedinAuthUrl();
    window.location.href = url;
  }

  return { signIn };
};

export default useLinkedinLogin;
