function getFileExtension(file) {
  // added conditional to handle /mpeg MIME for Safari/Firefox/Edge support & always return mp3 extension
  if (file.type.indexOf('audio/') >= 0) {
    return '.mp3';
  } else {
    const index = file.type.lastIndexOf('/') + 1;
    return `.${file.type.substr(index)}`;
  }
}

export default getFileExtension;
