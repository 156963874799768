export const setTBError = errorMessage => {
  return {
    type: 'SET_TB_ERROR',
    errorMessage,
  };
};

export const clearErrors = () => {
  return {
    type: 'CLEAR_ERRORS',
  };
};
