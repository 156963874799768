import React, { memo } from 'react';
import { Route, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AsyncComponent from './components/AsyncComponent/AsyncComponent';
import Favicon from './components/Media/Favicon/Favicon';
import { store } from './store';
import history from './browserHistory';
import { isIE } from './utils/userAgentHelpers';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import AuthCallback from 'components/Auth/AuthCallback/AuthCallback';
import { withKeycloak } from '@react-keycloak/web';
import TourSession from 'components/Session/TourSession';
import { useInitGtm } from 'hooks';

const AsyncForgotPassword = AsyncComponent(() => {
  return null;
});

const AsyncHeader = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Login" */ './components/HeaderContainer/HeaderContainer'
  )
);

const AsyncLoginLanding = AsyncComponent(() =>
  import(/* webpackChunkName: "Login" */ './components/Auth/Login/LoginLanding')
);

const AsyncSignUp = AsyncComponent(() =>
  import(/* webpackChunkName: "Login" */ './components/Auth/SignUp/SignUp')
);
const AsyncHome = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Home" */ './components/HomeContainer/HomeContainer'
  )
);
const AsyncSidebar = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Sidebar" */ './components/Sidebar/SidebarWrapper'
  )
);
const AsyncTourEditor = AsyncComponent(() =>
  import(
    /* webpackChunkName: "CreateTour" */ './components/TourEditorContainer/TourEditorContainer'
  )
);
const AsyncStopEditor = AsyncComponent(() =>
  import(
    /* webpackChunkName: "StopEditor" */ './components/StopEditorContainer/StopEditorContainer'
  )
);
const AsyncMediaLibrary = AsyncComponent(() =>
  import(
    /* webpackChunkName: "MediaLibrary" */ './components/MediaLibraryContainer/MediaLibraryContainer'
  )
);
const AsyncView = AsyncComponent(() =>
  import(
    /* webpackChunkName: "View" */ './components/ViewWrapperContainer/ViewWrapperContainer'
  )
);
const AsyncTourStylesEditor = AsyncComponent(() =>
  import(
    /* webpackChunkName: "StylesEditor" */ './components/TourEditorContainer/TourEditor/TourStylesEditorContainer/TourStylesEditorContainer.js'
  )
);
const AsyncGeneralEditor = AsyncComponent(() =>
  import(
    /*webpackChunkName: "GeneralEditor" */ './components/TourEditorContainer/TourEditor/GeneralEditorContainer/GeneralEditorContainer'
  )
);
const AsyncTourSharePublish = AsyncComponent(() =>
  import(
    /*webpackChunkName: "TourSharePublish" */ './components/TourEditorContainer/TourEditor/TourSharePublishContainer/TourSharePublishContainer'
  )
);
const AsyncStopCards = AsyncComponent(() =>
  import(
    /* webpackChunkName: "TourStopCards" */ './components/TourEditorContainer/TourEditor/StopCards/StopCardWarpperContainer'
  )
);
const AsyncButtonsEditor = AsyncComponent(() =>
  import(
    /*webpackChunkName: "ButtonsEditor" */ './components/TourEditorContainer/TourEditor/ButtonsEditorContainer/ButtonsEditorContainer'
  )
);
const AsyncSubscriptionManager = AsyncComponent(() =>
  import(
    /* webpackChunkName: "SubscriptionManager" */ './components/SubscriptionManagerContainer/SubscriptionManagerContainer'
  )
);
const AsyncAccountManager = AsyncComponent(() =>
  import(
    /* webpackChunkName: "AccountManager" */ './components/AccountManagerContainer/AccountManagerContainer'
  )
);
const AsyncUpgradeSubscription = AsyncComponent(() =>
  import(
    /* webpackChunkName: "UpgradeSubscription" */ './components/SubscriptionManagerContainer/SubscriptionManager/UpgradeSubscription/UpgradeSubscription'
  )
);
const AsyncAccountSettings = AsyncComponent(() =>
  import(
    /* webpackChunkName: "ManageSubscription"*/ './components/SubscriptionManagerContainer/SubscriptionManager/AccountSettingsContainer/AccountSettingsContainer'
  )
);
const AsyncAccountsDashboard = AsyncComponent(() =>
  import(
    /* webpackChunkName: "AccountsDashboard"*/ './components/AccountsDashboard/AccountsDashboard'
  )
);
const AsyncHotspotsManager = AsyncComponent(() =>
  import(
    /* webpackChunkName: "HotspotsManager" */ './components/HotspotsManagerContainer/HotspotsManagerContainer'
  )
);

const AsyncUnnauthorized = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Unnauthorized" */ './components/Pages/Error/Unnauthorized'
  )
);

const AsyncShareError404 = AsyncComponent(() =>
  import(
    /* webpackChunkName: "ShareError404" */ './components/Pages/Error/ShareError404'
  )
);
const AsyncCMSError404 = AsyncComponent(() =>
  import(
    /* webpackChunkName: "CMSError404" */ './components/Pages/Error/CMSError404'
  )
);
const AsyncAccountNotFound = AsyncComponent(() =>
  import(
    /* webpackChunkName: "CMSError404" */ './components/Pages/Error/LoginError'
  )
);
const AsyncAccountLocked = AsyncComponent(() =>
  import(
    /* webpackChunkName: "AccountLocked403" */ './components/Pages/Error/AccountLocked'
  )
);
const AsyncUnsupportedBrowser = AsyncComponent(() =>
  import(
    /* webpackChunkName: "UnsupportedBrowser" */ './components/Pages/UnsupportedBrowser/UnsupportedBrowser'
  )
);
const AsyncPrivacySettings = AsyncComponent(() =>
  import(
    /* webpackChunkName: "PrivacySettings" */ './components/PrivacySettingsContainer/PrivacySettingsContainer'
  )
);
const AsynciOSSafariMotion = AsyncComponent(() =>
  import(
    /* webpackChunkName: "UnsupportedChromeIos" */ './components/Pages/iOSSafariEnableMotion/iOSSafariEnableMotion'
  )
);
const AsynciOS13SafariMotion = AsyncComponent(() =>
  import(
    /* webpackChunkName: "UnsupportedChromeIos" */ './components/Pages/iOSSafariEnableMotion/iOS13SafariEnableMotion'
  )
);
const AsyncInvalidMapToken = AsyncComponent(() =>
  import(
    /* webpackChunkName: "InvalidMapToken" */ './components/Pages/Error/InvalidMapToken'
  )
);
const AsyncTourForms = AsyncComponent(() =>
  import(
    /*webpackChunkName: "TourSharePublish" */ './components/TourEditorContainer/TourEditor/FormEditor/FormEditor'
  )
);

const AsyncTranslations = AsyncComponent(() =>
  import(
    /*webpackChunkName: "TourSharePublish" */ './components/TourEditorContainer/TourEditor/Translations/Translations'
  )
);

const AsyncTranslationsV2 = AsyncComponent(() =>
  import(
    /*webpackChunkName: "TourSharePublish" */ './components/TourEditorContainer/TourEditor/TranslationsV2/Translations'
  )
);

const AsyncUserSettings = AsyncComponent(() =>
  import(
    /* webpackChunkName: "UserSettings" */ './components/SubscriptionManagerContainer/SubscriptionManager/UserSettingsContainer/UserSettingsContainer'
  )
);

const AsyncExpired = AsyncComponent(() =>
  import(/* webpackChunkName: "Expired" */ './components/Pages/Error/Expired')
);

const AsyncResetCache = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Expired" */ './components/CacheBuster/ResetCache'
  )
);

const Routes = ({ keycloak, keycloakInitialized }) => {
  useInitGtm();
  if (!keycloakInitialized) {
    return null;
  }
  if (isIE()) {
    return <AsyncUnsupportedBrowser />;
  }
  return (
    <Provider store={store}>
      <Router history={history}>
        <Favicon />
        {/* Public Routes */}
        <Route
          path="/"
          exact
          render={props => <AsyncLoginLanding {...props} />}
        />
        <Route
          path="/forgotpassword"
          exact
          render={props => <AsyncForgotPassword {...props} />}
        />
        <Route
          path="/login"
          exact
          render={props => <AsyncLoginLanding {...props} />}
        />
        <Route
          path="/signup"
          exact
          render={props => (
            <AsyncSignUp show="signup" keycloak={keycloak} {...props} />
          )}
        />
        <Route
          path="/tos/email/:email"
          exact
          render={props => (
            <AsyncSignUp
              keycloak={keycloak}
              show="tos"
              type="email"
              {...props}
            />
          )}
        />
        <Route
          path="/tos/:provider"
          exact
          render={({ match, ...props }) => (
            <AsyncSignUp
              keycloak={keycloak}
              show="tos"
              type={match.params.provider}
              match={match}
              {...props}
            />
          )}
        />
        <Route
          path="/tos/:provider/callback"
          exact
          render={({ match, ...props }) => (
            <>
              <AsyncSignUp
                show="tos"
                type={match.params.provider}
                match={match}
                hasChecked
                {...props}
              />
              <AuthCallback
                provider={match.params.provider}
                match={match}
                overlay
                {...props}
              />
            </>
          )}
        />
        <Route
          path={[
            '/:lang/share/:id/stop/:activestop',
            '/share/:id/stop/:activestop',
          ]}
          exact
          render={({ match, ...props }) => {
            const baseUrl = match.params.lang
              ? `/${match.params.lang}/share/${match.params.id}/stop`
              : `/share/${match.params.id}/stop`;
            return (
              <AsyncView
                {...props}
                tourId={match.params.id}
                match={match}
                activeStop={parseInt(match.params.activestop, 10)}
                baseUrl={baseUrl}
                publicTour
              />
            );
          }}
        />
        <Route
          path={['/error', '/share/error']}
          render={props => <AsyncResetCache errorPage />}
        />
        <Route
          path="/share/error/ios-device-motion"
          exact
          component={AsynciOSSafariMotion}
        />
        <Route
          path="/share/error/ios-13-device-motion"
          exact
          component={AsynciOS13SafariMotion}
        />
        <Route path="/share/error/404" exact component={AsyncShareError404} />
        <Route path="/error/404" exact component={AsyncCMSError404} />
        <Route
          path="/error/acountnotfound"
          exact
          component={AsyncAccountNotFound}
        />
        <Route
          path="/error/unauthorized"
          exact
          render={props => (
            <>
              <AsyncHeader basic {...props} />
              <AsyncUnnauthorized {...props} />
            </>
          )}
        />
        <Route
          path="/error/accountexpired"
          exact
          render={props => (
            <>
              <AsyncHeader basic {...props} />
              <AsyncExpired {...props} type="account" />
            </>
          )}
        />
        <Route
          path="/error/tourexpired"
          exact
          render={props => (
            <>
              <AsyncExpired {...props} type="tour" />
            </>
          )}
        />
         <Route
          path="/error/accountlocked"
          exact
          render={props => (
            <>
              <AsyncHeader basic {...props} />
              <AsyncAccountLocked {...props} type="tour" />
            </>
          )}
        />
        {/* End Public Routes */}

        <PrivateRoute
          path={['/home', '/:lang/home']}
          component={props => <AsyncHome {...props} />}
        />
        {!!keycloak.authenticated && (
          <React.Fragment>
            <Route
              path="/home/medialibrary"
              render={props => <AsyncMediaLibrary {...props} />}
            />
            <Route
              path={['/home/:tid/edit', '/home/:tid/hotstpots-manager']}
              render={props => <TourSession {...props} />}
            />
            <Route
              path="/home/settings"
              render={props => {
                return (
                  <AsyncSubscriptionManager
                    {...props}
                    path={props.location.pathname}
                    routes={[
                      {
                        path: '/home/settings/subscription/upgrade',
                        component: AsyncUpgradeSubscription,
                      },
                      {
                        path: '/home/settings/account',
                        component: AsyncAccountSettings,
                      },
                      {
                        path: '/home/settings/user',
                        component: AsyncUserSettings,
                      },
                    ]}
                  />
                );
              }}
            />
            <Route
              path="/home/accountsmanager"
              render={props => {
                return (
                  <AsyncAccountManager
                    {...props}
                    path={props.location.pathname}
                    history={history}
                  />
                );
              }}
            />
            <Route
              path="/home/accounts"
              render={props => {
                return (
                  <AsyncAccountsDashboard
                    {...props}
                    path={props.location.pathname}
                    history={history}
                  />
                );
              }}
            />
            <Route
              path="/home/newtour/:id/:subRoute"
              exact
              render={({ match, ...props }) => {
                return (
                  <AsyncTourEditor
                    {...props}
                    path={props.location.pathname}
                    tourId={match.params.id}
                    newTour
                    routes={[
                      {
                        dataCy: 'tour-general-btn',
                        name: 'General',
                        path: `/home/newtour/${match.params.id}/general`,
                        component: AsyncGeneralEditor,
                      },
                      {
                        dataCy: 'tour-stops-btn',
                        name: 'Tour Stops',
                        path: `/home/newtour/${match.params.id}/stops`,
                        component: AsyncStopCards,
                      },
                      {
                        dataCy: 'tour-buttons-btn',
                        name: 'Buttons',
                        path: `/home/newtour/${match.params.id}/buttons`,
                        component: AsyncButtonsEditor,
                      },
                      {
                        dataCy: 'tour-styles-btn',
                        name: 'Styles',
                        path: `/home/newtour/${match.params.id}/styles`,
                        component: AsyncTourStylesEditor,
                      },
                      {
                        dataCy: 'tour-forms-btn',
                        name: 'Forms',
                        path: `/home/newtour/${match.params.id}/forms`,
                        component: AsyncTourForms,
                      },
                      {
                        dataCy: 'tour-translate-btn',
                        name: 'Translations',
                        tourId: match.params.id,
                        path: `/home/newtour/${match.params.id}/translations`,
                        component: AsyncTranslations,
                      },
                      {
                        dataCy: 'tour-share-btn',
                        name: 'Share & Publish',
                        path: `/home/newtour/${match.params.id}/publish`,
                        component: AsyncTourSharePublish,
                      },
                    ]}
                  />
                );
              }}
            />
            <Route
              path="/home/:id/edit/:subRoute"
              exact
              render={({ match, ...props }) => {
                if (match.params.subRoute !== 'newstop') {
                  // treats `/newstop` as a subRoute therefore renders the tour editor header so check & render null
                  return (
                    <AsyncTourEditor
                      {...props}
                      path={props.location.pathname}
                      tourId={match.params.id}
                      routes={[
                        {
                          dataCy: 'tour-general-btn',
                          name: 'General',
                          path: `/home/${match.params.id}/edit/general`,
                          component: AsyncGeneralEditor,
                        },
                        {
                          dataCy: 'tour-stops-btn',
                          name: 'Tour Stops',
                          path: `/home/${match.params.id}/edit/stops`,
                          component: AsyncStopCards,
                        },
                        {
                          dataCy: 'tour-buttons-btn',
                          name: 'Buttons',
                          path: `/home/${match.params.id}/edit/buttons`,
                          component: AsyncButtonsEditor,
                        },
                        {
                          dataCy: 'tour-styles-btn',
                          name: 'Styles',
                          path: `/home/${match.params.id}/edit/styles`,
                          component: AsyncTourStylesEditor,
                        },
                        {
                          dataCy: 'tour-forms-btn',
                          name: 'Forms',
                          path: `/home/${match.params.id}/edit/forms`,
                          component: AsyncTourForms,
                        },
                        {
                          dataCy: 'tour-translate-btn',
                          name: 'Translations',
                          path: `/home/${match.params.id}/edit/translations`,
                          component: AsyncTranslationsV2,
                        },
                        {
                          dataCy: 'tour-share-btn',
                          name: 'Share & Publish',
                          path: `/home/${match.params.id}/edit/publish`,
                          component: AsyncTourSharePublish,
                        },
                      ]}
                    />
                  );
                }
                return null;
              }}
            />
            <Route
              path="/home/:id/edit/newstop"
              render={({ match, ...props }) => {
                return (
                  <AsyncSidebar route="edit-stop">
                    <AsyncStopEditor
                      {...props}
                      tourId={match.params.id}
                      path={props.location.pathname}
                      newStop
                    />
                  </AsyncSidebar>
                );
              }}
            />
            <Route
              path="/home/:id/edit/stop/:stopId"
              render={({ match, ...props }) => {
                return (
                  <AsyncSidebar route="edit-stop">
                    <AsyncStopEditor
                      {...props}
                      path={props.location.pathname}
                      stopId={match.params.stopId}
                      tourId={match.params.id}
                    />
                  </AsyncSidebar>
                );
              }}
            />
            <Route
              path={[
                '/home/view/:id/stop/:activestop',
                '/:lang/home/view/:id/stop/:activestop',
              ]}
              render={({ match, ...props }) => {
                const baseUrl = match.params.lang
                  ? `/${match.params.lang}/home/view/${match.params.id}/stop`
                  : `/home/view/${match.params.id}/stop`;
                return (
                  <AsyncView
                    {...props}
                    match={match}
                    tourId={match.params.id}
                    previewTour
                    activeStop={parseInt(match.params.activestop, 10)}
                    baseUrl={baseUrl}
                  />
                );
              }}
            />
            <Route
              path="/home/privacy"
              render={({ ...props }) => (
                <AsyncPrivacySettings {...props} auth={keycloak} />
              )}
            />
            <Route
              path="/home/:id/hotspots-manager/:stopId"
              render={({ match, ...props }) => {
                return (
                  <AsyncHotspotsManager
                    {...props}
                    history={history}
                    path={props.location.pathname}
                    stopId={match.params.stopId}
                    tourId={match.params.id}
                  />
                );
              }}
            />
          </React.Fragment>
        )}
        <Route path="/token/error/404" exact component={AsyncInvalidMapToken} />
      </Router>
    </Provider>
  );
};
export default withKeycloak(memo(Routes));
