import React from 'react';
import { PuffLoader } from 'react-spinners';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import {
  useGoogleLogin,
  useLinkedinLogin,
  useConcept3dLogin,
} from '../../../hooks';

import {
  postNewGoogleUser,
  postNewLinkedinUser,
  postNewConcept3DUser,
} from 'api/users';

import './AuthCallback.scss';

const AuthCallback = ({ provider, overlay, fadeout }) => {
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const isGoogle = provider === 'google';
  const isLinkedin = provider === 'linkedin';
  const isConcept3d = provider === 'concept3d';

  const successCallback = () => {
    keycloak.login({
      idpHint: provider,
      redirectUri: window.location.origin,
    });
  };

  const onSuccess = async (code = '') => {
    const data = { code, privacyPolicy: true };
    if (isGoogle) {
      try {
        await postNewGoogleUser(data);
        successCallback();
      } catch (error) {
        history.push({
          pathname: '/tos/google',
          state: {
            error: error.message,
          },
        });
      }
    }
    if (isLinkedin) {
      try {
        await postNewLinkedinUser(data);
        successCallback();
      } catch (error) {
        history.push({
          pathname: '/tos/linkedin',
          state: {
            error: error.message,
          },
        });
      }
    }
    if (isConcept3d) {
      try {
        const res = await postNewConcept3DUser(data);
        console.log(res);
        successCallback();
      } catch (error) {
        history.push({
          pathname: '/tos/concept3d',
          state: {
            error: error.message,
          },
        });
      }
    }
  };

  const onFailure = error => {
    console.log(error);
  };

  useGoogleLogin({
    onSuccess,
    onFailure,
    isCallback: isGoogle,
  });

  useLinkedinLogin({
    onSuccess,
    onFailure,
    isCallback: isLinkedin,
  });

  useConcept3dLogin({
    onSuccess,
    onFailure,
    isCallback: isConcept3d,
  });

  const authCallbackClasses = cx('auth-callback', {
    overlay,
    fadeout,
  });

  return (
    <div className={authCallbackClasses}>
      <div className="auth-callback__spinner-wrapper">
        <i className="material-icons">lock</i>
        <PuffLoader color="#009b65" />
      </div>
    </div>
  );
};

export default AuthCallback;
