import { buildAuthHeaders } from '../../utils/buildAuthHeaders';
import { handleResponse } from 'api/helpers/helpers';

export const _sendHubspotEvent = async data => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  const status = localStorage.getItem('status');
  try {
    if (status === 'pursuit') {
      const res = await fetch(
        `${process.env.REACT_APP_TOUR_API}/v2/${aid}/hubspot`,
        {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(data),
          credentials: 'include',
        }
      );
      await handleResponse(res);
    }
    return 1;
  } catch (error) {
    throw error;
  }
};
