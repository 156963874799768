import { buildAuthHeaders } from '../../utils/buildAuthHeaders';
import { handleResponse } from 'api/helpers/helpers';

export const getStopsByTour = async tourId => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/tours/${tourId}/stops`,
      {
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const getStop = async sid => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${sid}`,
      {
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const postStop = async data => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const updateStop = async (data, tourId) => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  const queryParams = tourId ? `?tourId=${tourId}` : '';
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${data.id}${queryParams}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(data),
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const deleteStop = async (sid, tourId) => {
  const myHeaders = buildAuthHeaders();
  const aid = localStorage.getItem('aid');
  const queryParams = tourId ? `?tourId=${tourId}` : '';
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${sid}${queryParams}`,
      {
        method: 'DELETE',
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};
