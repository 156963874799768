export const initialState = {
  mediaType: '',
};

export const fileUploader = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FILE_UPLOADER::MEDIA_TYPE_TO_UPLOAD': {
      return Object.assign({}, state, {
        mediaType: action.mediaType,
      });
    }
    default:
      return state;
  }
};

export default fileUploader;
