export const PANOS = 'panos';
export const IMAGES = 'images';
export const ICONS = 'icons';
export const VIDEOS = 'videos';
export const AUDIO = 'audio';
export const TEXT = 'text';
export const LOGO = 'logo';
export const VIDEOS360 = 'videos360';
export const EMBED = 'embed';
export const HIGHLIGHT = 'highlight';
export const URL = 'url';
export const STOP = 'stop';
export const S3TOURBUCKET = '360tours';
export const HSFORMSAPI =
  'https://api.hsforms.com/submissions/v3/integration/submit';
export const DEFAULTPANO =
  'https://s3-us-west-2.amazonaws.com/images-mb01-resized/SJvNC13nz/index.html';
export const DOWNLOADTIMEOUT = 20; // multiplied by 3
export const PERMISSIONS = {
  manageTours: 'manageTours',
  manageUsers: 'manageUsers',
  deleteAccount: 'deleteAccount',
  publishTours: 'publishTours',
  manageAccount: 'manageAccount',
};
export const AUDIOFILESURL =
  'https://res.cloudinary.com/concept3d/video/upload/vc_auto/audio_files/';

export const IDENTITYPOOL = 'us-west-2:8d221ae4-c54f-4155-9f62-af5b0015bc0a';
export const S3BASEBUCKET = 'xplorertourmedia';
export const CLOUDINARYBASEPATH = 'https://res.cloudinary.com/concept3d';
export const DISPLAY_FORM_TIMEOUT = 1500;
export const TOURTRANSLATIONFIELDS = {
  tour: {
    fields: [
      {
        key: 'name',
        type: 'input',
      },
      {
        key: 'description',
        type: 'wysiwyg',
        basicEditor: true,
      },
    ],
  },
  stop: {
    fields: [
      {
        key: 'name',
        type: 'input',
      },
      {
        key: 'description',
        type: 'wysiwyg',
        basicEditor: false,
      },
    ],
  },
  button: {
    standard: {
      fields: [
        {
          key: 'title',
          type: 'input',
        },
        {
          key: 'url',
          type: 'input',
          enabled: false,
        },
      ],
    },
    dropdown: {
      fields: [
        {
          key: 'title',
          type: 'input',
        },
        {
          key: 'dropdownOpts',
          fields: [
            {
              key: 'name',
              type: 'input',
            },
            {
              key: 'url',
              type: 'input',
              enabled: false,
            },
          ],
        },
      ],
    },
  },
};

export const MAX_FILE_SIZES = {
  '512kb': 512000,
  '10mb': 10000000,
  '40mb': 40000000,
  '100mb': 100000000,
  '500mb': 500000000,
  '1gb': 1000000000,
};

export const PRIVACY_POLICY_LINK =
  'https://concept3d.com/concept3d-privacy-policy/';

export const NANO_ID_SIZE = 10;
