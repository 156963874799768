import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer.js';
import { createBrowserHistory } from 'history';
import { checkIfInIframe } from 'utils/iframe';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

export const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];
export const store = createStore(
  rootReducer,
  devTools,
  applyMiddleware(...middleware)
);

store.subscribe(() => {
  if (!checkIfInIframe()) {
    localStorage.setItem('tier', store.getState().account.tier);
    localStorage.setItem('status', store.getState().account.status);
  }
});
