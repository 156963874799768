// dependencies
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// general
import account from './account';
import config from './config';
import userCredentials from './auth';
import media from './media';
import publicTour from './publicTour';
import miniMap from './miniMap';
import snackBar from './snackBar';
import header from './header';
import hotspots from './hotspots';
import translations from './translations';
import forms from './forms';
import errors from './errors';
import fileUploader from './fileUploader';

// for multi user
import stops from './stops';
import tours from './tours';
import sessions from './sessions';
import accountUsers from './accountUsers';

// for custom events
import hubspot from './hubspot';

const rootReducer = combineReducers({
  router: routerReducer,
  userCredentials,
  account,
  config,
  media,
  publicTour,
  miniMap,
  snackBar,
  header,
  hotspots,
  translations,
  forms,
  errors,
  stops,
  tours,
  sessions,
  accountUsers,
  hubspot,
  fileUploader,
});

export default rootReducer;
