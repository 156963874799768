import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import { handleResponse } from './helpers/helpers';

export const getAccountSessions = async () => {
  const aid = localStorage.getItem('aid');
  const myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/sessions`,
      {
        method: 'GET',
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const updateSessionTour = async tid => {
  const aid = localStorage.getItem('aid');
  const myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/sessions/${tid}`,
      {
        method: 'PUT',
        headers: myHeaders,
        credentials: 'include',
        body: JSON.stringify({}),
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const removeTourFromSession = async tid => {
  const aid = localStorage.getItem('aid');
  const myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TOUR_API}/v2/${aid}/sessions/${tid}`,
      {
        method: 'DELETE',
        headers: myHeaders,
        credentials: 'include',
      }
    );
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};
