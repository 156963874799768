import { useEffect, useState } from 'react';
import { _getVideoHotspotThumbnail } from '../api/hotspots';
import cat404 from '../images/cat-404.jpg';

export const useGetVideoThumb = url => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    const craftSrc = async video => {
      const response = await _getVideoHotspotThumbnail(url);
      if (response) {
        return response;
      } else {
        return cat404;
      }
    };
    if (url) {
      // res is returned as a blob() which requires us to read the stream with createObjectURL
      craftSrc(url).then(res => setThumbnailUrl(URL.createObjectURL(res)));
    }
  }, [url]);

  return thumbnailUrl;
};
