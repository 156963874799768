import Loadable from 'react-loadable';
import LoadingComponent from './Loading/LoadingComponent';

const AsyncComponent = func =>
  Loadable({
    loader: func,
    loading: LoadingComponent,
    delay: 2000,
  });

export default AsyncComponent;
