import omit from 'lodash.omit';

export const initialState = {
  tours: {},
  status: '',
  errorMessage: '',
};

const tours = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_TOUR': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tour.id]: {
            ...state.tours[action.tour.id],
            ...action.tour,
          },
        },
      };
    }
    case 'DELETE_TOUR': {
      return {
        ...state,
        tours: omit(state.tours, action.tourId),
      };
    }
    case 'TOURS_STATUS': {
      return {
        ...state,
        status: action.status,
        errorMessage: action.errorMessage,
      };
    }
    case 'CLEAR_TOURS': {
      return {
        ...state,
        tours: {},
      };
    }
    case 'ADD_STOP_TO_TOUR': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            stopIds: [...state.tours[action.tourId].stopIds, action.stopId],
          },
        },
      };
    }
    case 'UPDATE_TOUR_LANGUAGES': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: false,
            languages: {
              ...state.tours[action.tourId].languages,
              ...action.language,
            },
          },
        },
      };
    }
    case 'TOUR_UP_TO_DATE': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: action.uptoDate,
          },
        },
      };
    }
    case 'TOUR_PUBLISH_SUCCESS': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: true,
            published: true,
          },
        },
      };
    }
    // button reducers
    case 'SAVE_TOUR_BUTTON': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: false,
            buttons: [...state.tours[action.tourId].buttons, action.button],
          },
        },
      };
    }
    case 'UPDATE_TOUR_BUTTON': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: false,
            buttons: state.tours[action.tourId].buttons.map(button =>
              button.id === action.button.id ? action.button : button
            ),
          },
        },
      };
    }
    case 'DELETE_TOUR_BUTTON': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: false,
            buttons: state.tours[action.tourId].buttons.filter(
              button => button.id !== action.buttonId
            ),
          },
        },
      };
    }
    case 'REORDER_TOUR_BUTTONS': {
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.tourId]: {
            ...state.tours[action.tourId],
            uptoDate: false,
            buttons: action.buttonIds.map(buttonId =>
              state.tours[action.tourId].buttons.find(
                button => button.id === buttonId
              )
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default tours;
