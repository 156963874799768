import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import { convertObjectToFormData } from '../utils/returnFormData';

export const _createHotspot = async (aid, stopId, type, hotspotData) => {
  let myHeaders = buildAuthHeaders(true);
  const body = convertObjectToFormData(hotspotData, type);
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${stopId}/hotspots?type=${type}`,
    {
      method: 'POST',
      headers: myHeaders,
      body,
      credentials: 'include',
    }
  ).then(response => {
    if (!response.ok) {
      throw new Error(response.status)
    }
    return response.json()
  })
};

export const _updateHotspot = async (
  aid,
  stopId,
  type,
  hotspotData,
  hotspotId
) => {
  let myHeaders = buildAuthHeaders(true);
  const body = convertObjectToFormData(hotspotData, type);
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${stopId}/hotspots/${hotspotId}?type=${type}`,
    {
      method: 'PUT',
      headers: myHeaders,
      body,
      credentials: 'include',
    }
  ).then(response => {
    if (!response.ok) {
      throw new Error(response.status)
    }
    return response.json()
  })
};

export const _deleteHotspot = async (aid, stopId, hotspotId, tourId) => {
  let myHeaders = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/${aid}/stops/${stopId}/hotspots/${hotspotId}?tourId=${tourId}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      credentials: 'include',
    }
  )
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data.tour;
    });
};

export const _getVideoHotspotThumbnail = async url => {
  let myHeaders = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/v2/temp/media/videos/thumbnail?url=${url}`,
    {
      headers: myHeaders,
      credentials: 'include',
    }
  ).then(res => {
    return res.blob();
  });
};

// blob fetcher for cloudinary
export const getCloudinaryBlob = async url => {
  return fetch(url, {}).then(res => {
    return res.blob();
  });
};
