import * as toursApi from 'api/v2/tours';
import { batch } from 'react-redux';
import { saveStop } from './stopActions';

export const getTours = (aid, clearState) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      clearState && dispatch(clearTours());
      const tours = await toursApi.getToursByAid({ aid });
      tours.forEach(tour => dispatch(saveTour(tour)));
      dispatch(setDone());
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong fetching tours.')
      );
    }
  };
};

export const getTour = tourId => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const tour = await toursApi.getTour(tourId);
      dispatch(saveTour(tour));
      dispatch(setDone());
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong fetching the tour.')
      );
    }
  };
};

export const postTour = (tourData, callback) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const tour = await toursApi.postTour(tourData);
      batch(() => {
        dispatch(saveTour(tour));
        dispatch(setDone());
      });
      typeof callback === 'function' && callback();
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong saving the tour.')
      );
    }
  };
};

export const updateTour = tourData => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const tour = await toursApi.updateTour(tourData);
      batch(() => {
        dispatch(saveTour(tour));
        dispatch(setDone());
      });
    } catch (error) {
      console.log(error);
      dispatch(
        setError(error.message || 'Something went wrong saving the tour.')
      );
    }
  };
};

export const deleteTour = id => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      await toursApi.deleteTour(id);
      dispatch(deleteByTourId(id));
      dispatch(setDone());
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong deleting the tour.')
      );
    }
  };
};

export const deleteTourStops = (id, data) => {
  return async (dispatch, getState) => {
    dispatch(setSaving());
    try {
      const tour = await toursApi.deleteTourStops(id, data);
      batch(() => {
        dispatch(saveTour(tour));
        dispatch(setDone());
      });
    } catch (error) {
      dispatch(
        setError(error.message || 'Something went wrong deleting stops.')
      );
    }
  };
};

export const revertTour = (tourId, callback) => {
  return async (dispatch, getState) => {
    try {
      const { tour, stops = [] } = await toursApi.revertTourChanges(tourId);

      dispatch(saveTour(tour));
      stops.forEach(stop => {
        dispatch(saveStop(stop));
      });
    } catch (error) {
      console.error(error);
      dispatch(
        setError(error.message || 'Something went wrong reverting the tour.')
      );
    } finally {
      typeof callback === 'function' && callback();
    }
  };
};

export const setTourUpToDate = (tourId, uptoDate = false) => {
  return dispatch => {
    dispatch(
      saveTour({
        id: tourId,
        uptoDate,
      })
    );
  };
};

export const clearTours = () => {
  return {
    type: 'CLEAR_TOURS',
  };
};

export const saveTour = tour => {
  return {
    type: 'SAVE_TOUR',
    tour,
  };
};

export const setUpToDate = (tourId, uptoDate) => {
  return {
    type: 'TOUR_UP_TO_DATE',
    tourId,
    uptoDate,
  };
};

export const tourPublishSuccess = tourId => {
  return {
    type: 'TOUR_PUBLISH_SUCCESS',
    tourId,
  };
};

export const deleteByTourId = tourId => {
  return {
    type: 'DELETE_TOUR',
    tourId,
  };
};

export const setSaving = () => {
  return {
    type: 'TOURS_STATUS',
    status: 'saving',
    errorMessage: '',
  };
};

export const setDone = () => {
  return {
    type: 'TOURS_STATUS',
    status: 'ok',
    errorMessage: '',
  };
};

export const setError = errorMessage => {
  return {
    type: 'TOURS_STATUS',
    status: 'error',
    errorMessage,
  };
};

export const addStopToTour = (tourId, stopId) => {
  return {
    type: 'ADD_STOP_TO_TOUR',
    tourId,
    stopId,
  };
};
