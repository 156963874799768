import omit from 'lodash.omit';

export const initialState = {
  accountUsers: {},
  roles: {},
  assignments: {},
  status: 'ok',
  errorMessage: '',
  errorCode: '',
};

const accountUsers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_ACCOUNT_USER': {
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          [action.user.id]: {
            ...state.accountUsers[action.user.id],
            ...action.user,
          },
        },
      };
    }
    case 'UPDATE_USER_ASSIGNMENTS': {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          ...action.newAssignments,
        },
      };
    }
    case 'SAVE_ACCOUNT_ROLE': {
      return {
        ...state,
        roles: {
          ...state.roles,
          [action.role.rid]: {
            ...state.roles[action.role.rid],
            ...action.role,
          },
        },
      };
    }
    case 'SAVE_ACCOUNT_ASSIGNMENT': {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [action.assignment.uid]: {
            ...state.assignments[action.assignment.uid],
            ...action.assignment,
          },
        },
      };
    }
    case 'ACCOUNT_USERS_STATUS': {
      return {
        ...state,
        status: action.status,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    }
    case 'DELETE_ACCOUNT_USER': {
      return {
        ...state,
        accountUsers: omit(state.accountUsers, action.id),
      };
    }
    case 'RESET_ACCOUNT_USERS': {
      return initialState;
    }
    default:
      return state;
  }
};

export default accountUsers;
