export const selectUserAssignments = (state, uid) =>
  state.accountUsers.assignments[uid];

export const selectAccountOwnerUid = state => {
  const ownerRID = selectRole(state, 'owner').rid;

  for (let key in state.accountUsers.assignments) {
    if (state.accountUsers.assignments[key].rid === ownerRID) {
      return state.accountUsers.assignments[key].uid;
    }
  }
};

export const selectRole = (state, role) => {
  const roles = state.accountUsers.roles;
  for (let key in roles) {
    if (roles[key].name === role) {
      return roles[key];
    }
  }
};
