import React from 'react';
import DialogModal from 'components/Modal/DialogModal/DialogModal';
import useCacheBuster from 'hooks/useCacheBuster';
import ResetCache from './ResetCache';
import './CacheBuster.scss';

const CacheBuster = () => {
  const { isUpToDate } = useCacheBuster();

  return (
    <DialogModal
      isOpen={!isUpToDate}
      className="cache-buster-modal"
      hideBackground
    >
      <div className="cache-buster-modal__content-wrapper">
        <p>
          Your app is out of date. Please refresh and reload to get the latest
          experience
        </p>
        <ResetCache />
      </div>
    </DialogModal>
  );
};

export default CacheBuster;
