import { batch } from 'react-redux';
import getFileExtension from '../utils/getFileExtension';
import * as constants from '../constants';

import {
  getMediaFromDatabase,
  saveMediaToDatabase,
  updateMediaDatabase,
  savePanoUrlToDatabase,
  updatePanoDatabase,
  deleteMediaFromDatabase,
  triggerKRPanoBuild,
} from '../api/media';

import { saveCustomTourStyles } from '../api/tours';
import { buildParams, s3Upload } from 'api/s3';
import { saveTour, setDone, setSaving } from './tourActions';
import { getNewId } from 'utils/id';

// TODO: should really return the new id on the be
// instead of the whole media by type
export const checkMediaExt = (type, mediaByType, id) => {
  switch (type) {
    case constants.VIDEOS360: {
      const m3u8Ext = id.replace('.mp4', '.m3u8');
      return mediaByType[m3u8Ext] ? m3u8Ext : id;
    }
    // it shouldn't hit this really. Other media types don't change exts
    default: {
      return id;
    }
  }
};

export const getMedia = showTourData => {
  return async (dispatch, getState) => {
    dispatch(mediaFetching());
    try {
      const aid = localStorage.getItem('aid');
      const res = await getMediaFromDatabase(aid, showTourData);

      batch(() => {
        dispatch(saveAllAccountMediaPayload(res));
        dispatch(mediaFetchSuccess());
      });
    } catch (error) {
      dispatch(mediaFetchError(error));
    }
  };
};

export const saveNewMedia = (mediaData, callback) => {
  return async dispatch => {
    try {
      const res = await saveMediaToDatabase(mediaData);
      const mediaByType = res[mediaData.type];
      batch(() => {
        dispatch(saveNewMediaPayload(mediaData.type, mediaByType));
        dispatch(mediaUploadComplete());
      });
      if (typeof callback === 'function') {
        if (!mediaData?.id) mediaData.id = checkMediaExt(mediaData.type, mediaByType, mediaData.id);
        callback(mediaData);
      }
    } catch (error) {
      dispatch(mediaUploadFailed(error));
    }
  };
};

export const updateMediaMetadata = mediaData => {
  return async dispatch => {
    try {
      const res = await updateMediaDatabase(mediaData);
      dispatch(updateMedia(mediaData.type, res[mediaData.type][mediaData.id]));
    } catch (error) {
      dispatch(mediaUploadFailed(error));
    }
  };
};

export const saveNewPano = panoData => {
  return async dispatch => {
    try {
      const { Attributes } = await savePanoUrlToDatabase(panoData);
      batch(() => {
        dispatch(saveNewPanoPayload(Attributes.panos[panoData.id]));
        dispatch(mediaUploadComplete());
      });
    } catch (error) {
      dispatch(mediaUploadFailed(error));
    }
  };
};

export const updatePanoMetadata = panoData => {
  return async dispatch => {
    try {
      const res = await updatePanoDatabase(panoData);
      dispatch(updatePano(res[panoData.id]));
    } catch (error) {
      dispatch(mediaUploadFailed(error));
    }
  };
};

export const deleteMedia = (id, type) => {
  return async dispatch => {
    batch(() => {
      dispatch(mediaDeleting());
      dispatch(mediaFailReset());
    });

    try {
      const aid = localStorage.getItem('aid');
      const deleteMediaData = {
        aid,
        id,
        type,
      };
      await deleteMediaFromDatabase(deleteMediaData);
      batch(() => {
        dispatch(mediaDeleted());
        dispatch(getMedia(true));
      });
    } catch (error) {
      dispatch(mediaDeleteFailed(error));
    }
  };
};

export const uploadNewMedia = ({
  file,
  mediaType,
  name,
  description,
  alt,
  transcript,
  componentCB,
  permissions,
}) => {
  return async dispatch => {
    try {
      dispatch(startMediaUpload());
      const id = getNewId() + getFileExtension(file);
      let ratio;
      if (mediaType === constants.PANOS) {
        const _URL = window.URL || window.webkitURL;
        const image = new Image();
        image.onload = function() {
          ratio = this.width / this.height;
        };
        image.src = _URL.createObjectURL(file);
      }

      dispatch(startRawS3Upload());
      const params = buildParams({
        id,
        file,
        mediaType,
        useEnv: mediaType === constants.ICONS,
      });

      const data = await s3Upload(params);
      let url = file.name;
      if (mediaType === constants.PANOS) url = data.Location;
      else if (mediaType === constants.ICONS) url = data.Key;
      const mediaData = {
        permissions,
        aid: localStorage.getItem('aid'),
        id,
        name,
        description,
        alt,
        transcript,
        ratio,
        type: mediaType,
        url,
      };
      if (file) {
        dispatch(startMediaBuild());
        if (mediaType === constants.PANOS) {
          startKRPanoBuild(mediaData, dispatch, componentCB);
        } else {
          batch(() => {
            dispatch(startMediaSave());
            dispatch(saveNewMedia(mediaData, componentCB));
          });
        }
      }
    } catch (error) {
      dispatch(mediaUploadFailed({ message: 'ERROR DURING UPLOAD' }));
      console.error('ERROR DURING UPLOAD', error);
    }
  };
};

export const startKRPanoBuild = (mediaData, dispatch, componentCB) => {
  triggerKRPanoBuild(mediaData).then(data => {
    if (data.Error) {
      // if Lambda upload fails we cancel the upload, trigger error to true and throw an error in the console
      dispatch(mediaUploadFailed({ message: 'ERROR DURING UPLOAD' }));
      console.error(data.Error, 'ERROR DURING UPLOAD');
    } else {
      const url = JSON.parse(data.Payload);
      const panoDbData = {
        aid: mediaData.aid,
        id: mediaData.id,
        ratio: mediaData.ratio,
        src: decodeURIComponent(mediaData.url),
        url: url + `index.html`,
        name: mediaData.name || null,
        description: mediaData.description || null,
        alt: mediaData.alt || null,
      }; //determine this after figuring out datastruct with murad and gregg
      batch(() => {
        dispatch(startMediaSave());
        dispatch(saveNewPano(panoDbData)); // handle saving new pano to database
      });

      if (typeof componentCB === 'function') componentCB(panoDbData);
    }
  });
};

export const saveTourStyles = styles => {
  return async dispatch => {
    dispatch(setSaving());
    await saveCustomTourStyles(styles)
      .then(({ tourId, tourStyles }) => {
        batch(() => {
          dispatch(
            saveTour({ id: tourId, styles: tourStyles, uptoDate: false })
          );
          dispatch(mediaUploadComplete());
          dispatch(setDone());
        });
      })
      .catch(err => console.error(err));
  };
};

export const uploadTourStyles = ({
  tourId,
  primaryColor,
  logoId,
  logoURL,
  logoToUpload,
  faviconId,
  faviconToUpload,
  hotspotIcons,
  hotspotIconsToUpload,
  buttonStyles,
  uptoDate,
  permissions,
  componentCB,
}) => {
  return async dispatch => {
    dispatch(setSaving());
    if (logoToUpload || faviconToUpload || hotspotIconsToUpload.length > 0) {
      const mediaToUpload = [
        logoToUpload,
        faviconToUpload,
        ...hotspotIconsToUpload,
      ].filter(m => m !== null);

      let userObj = {
        aid: localStorage.getItem('aid'),
        id: tourId,
        uptoDate,
        permissions,
      };

      let stylesObj = {
        logoURL,
        primaryColor,
        buttonStyles,
      };

      dispatch(startMediaUpload());
      let tmpLogoId = logoId;
      let tmpFaviconId = faviconId;
      let newHotspotIcons = { ...hotspotIcons };

      await Promise.all(
        mediaToUpload.map(async file => {
          const id = getNewId() + getFileExtension(file);
          dispatch(startRawS3Upload());
          const isIcon = file.fileType === 'icons';
          const params = buildParams({
            id,
            file,
            mediaType: !isIcon ? 'images' : 'icons',
            useEnv: isIcon,
          });

          const data = await s3Upload(params);
          tmpLogoId = file.fileType === 'logo' ? id : tmpLogoId;
          tmpFaviconId = file.fileType === 'favicon' ? id : tmpFaviconId;

          let mediaIds = {
            ...(file.fileType === 'logo'
              ? { logoId: id }
              : { logoId: tmpLogoId }),
            ...(file.fileType === 'favicon'
              ? { faviconId: id }
              : { faviconId: tmpFaviconId }),
          };

          if (isIcon) {
            const iconMediaData = {
              permissions,
              aid: localStorage.getItem('aid'),
              id,
              name: file.name,
              description: '',
              type: 'icons',
              url: data.key,
            };
            await dispatch(saveNewMedia(iconMediaData));
            newHotspotIcons[file.hotspotType] = { id, path: data.key };
          }

          stylesObj = {
            ...stylesObj,
            ...mediaIds,
            hotspotIcons: newHotspotIcons,
          };

          if (typeof componentCB === 'function') {
            componentCB({ mediaId: id, mediaType: file.fileType, hotspotType: file.hotspotType });
          }
        })
      ).then(() => {
        batch(() => {
          dispatch(startMediaBuild());
          dispatch(startMediaSave());
          dispatch(saveTourStyles({ ...userObj, styles: stylesObj }));
        });
      });
    } else {
      const mediaData = {
        aid: localStorage.getItem('aid'),
        id: tourId,
        styles: {
          primaryColor,
          logoId,
          logoURL,
          faviconId,
          buttonStyles,
        },
        uptoDate,
        permissions,
        hotspotIcons,
      };
      dispatch(saveTourStyles(mediaData));
    }
  };
};

export const uploadUrlVideos = ({
  id = getNewId(),
  mediaType = 'videos',
  url,
  name,
  description,
}) => {
  return (dispatch, getState) => {
    const { account } = getState();
    dispatch(startMediaUpload());
    const mediaData = {
      aid: localStorage.getItem('aid'),
      id,
      name,
      description,
      type: mediaType,
      url,
      permissions: account.permissions,
    };
    batch(() => {
      dispatch(startMediaBuild());
      dispatch(startMediaSave());
      dispatch(saveNewMedia(mediaData));
    });
  };
};

export const mediaFetching = () => {
  return {
    type: 'MEDIA_FETCHING',
  };
};

export const mediaFetchError = error => {
  return {
    type: 'MEDIA_FETCH_ERROR',
    error,
  };
};

export const mediaFetchSuccess = () => {
  return {
    type: 'MEDIA_FETCH_SUCCESS',
  };
};

export const saveNewMediaPayload = (mediaType, media) => {
  return {
    type: 'SAVE_ACCOUNT_MEDIA',
    mediaType,
    media,
  };
};

export const updateMedia = (mediaType, media) => {
  return { type: 'UPDATE_ACCOUNT_MEDIA', mediaType, media };
};

export const saveAllAccountMediaPayload = ({
  images,
  videos,
  panos,
  videos360,
  audio,
  icons = {},
}) => {
  return {
    type: 'SAVE_ALL_ACCOUNT_MEDIA',
    images,
    videos,
    panos,
    videos360,
    audio,
    icons,
  };
};

export const saveNewPanoPayload = pano => {
  return {
    type: 'SAVE_ACCOUNT_PANO',
    pano,
  };
};

export const updatePano = pano => {
  return {
    type: 'UPDATE_ACCOUNT_PANO',
    pano,
  };
};

export const startMediaUpload = () => {
  return {
    type: 'START_MEDIA_UPLOAD',
  };
};

export const startRawS3Upload = () => {
  return {
    type: 'START_RAW_UPLOAD',
  };
};

export const startMediaBuild = () => {
  return {
    type: 'START_MEDIA_BUILD',
  };
};

export const startMediaSave = () => {
  return {
    type: 'START_MEDIA_SAVE',
  };
};

export const mediaDeleting = () => {
  return {
    type: 'MEDIA_DELETING',
  };
};

export const mediaDeleted = () => {
  return {
    type: 'MEDIA_DELETED',
  };
};

export const mediaUploadComplete = () => {
  return {
    type: 'MEDIA_UPLOAD_COMPLETE',
  };
};

export const mediaUploadFailed = error => {
  return {
    type: 'MEDIA_UPLOAD_FAILED',
    error,
  };
};

export const mediaDeleteFailed = () => {
  return {
    type: 'MEDIA_DELETE_FAILED',
  };
};

export const mediaFailReset = () => {
  return {
    type: 'MEDIA_FAIL_RESET',
  };
};

export const toggleMainMediaStatus = bool => {
  return {
    type: 'TOGGLE_MAIN_MEDIA_STATUS',
    isMainMediaPlaying: bool,
  };
};
