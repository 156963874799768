import { customAlphabet } from 'nanoid';
import { NANO_ID_SIZE } from 'constants';

const alphabet =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

const nanoid = customAlphabet(alphabet, NANO_ID_SIZE);

export function getNewId() {
  return nanoid();
}
