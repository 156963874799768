import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPermissions } from 'selectors/accountSelector';
import {
  AUDIO,
  ICONS,
  IMAGES,
  MAX_FILE_SIZES,
  PANOS,
  VIDEOS,
  VIDEOS360,
} from '../constants';

export const useGetMaxFileSize = ({ type }) => {
  const permissions = useSelector(getPermissions);
  const videos360MaxFileSize = permissions?.maxFileSize?.videos360 || 40;
  const sizeByType = useMemo(
    () => ({
      [AUDIO]: '10mb',
      [ICONS]: '512kb',
      [IMAGES]: '40mb',
      [PANOS]: '40mb',
      [VIDEOS360]: `${videos360MaxFileSize}mb`,
      [VIDEOS]: '40mb',
    }),
    [videos360MaxFileSize]
  );

  const maxFileSize = useMemo(
    () => MAX_FILE_SIZES[sizeByType?.[type] || '40mb'],
    [sizeByType, type]
  );

  return { maxFileSize };
};
