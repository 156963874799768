import { batch } from 'react-redux';
import { fetchTourAccountFromC3D } from '../api/accounts';
import {
  fetchUserFromC3D,
  getUserRolePermissions,
  updateC3DUserPrivacyPolicy,
} from '../api/users';
import { saveAccountInfo } from './accountActions';
import { resetAccountUserData } from './accountUsersActions';
import { getMedia } from './mediaActions';
import { pollSessionData } from './sessionsActions';
import { getTours } from './tourActions';

export const handleUserSignIn = (userId, aidOverride, privacyCallback) => {
  return async dispatch => {
    //fetch profile from c3d accounts api
    const c3dUser = await fetchUserFromC3D(userId); //change to user
    if (!c3dUser.privacyPolicy) {
      typeof privacyCallback === 'function' && privacyCallback();
    }
    const rootAdmin = Boolean(
      c3dUser?.roles?.find(({ name }) => name === 'rootAdmin')
    );
    const mainUserAccount = c3dUser.accounts[0]; // setting this as the first account for now
    localStorage.setItem('default-aid', mainUserAccount);
    localStorage.setItem('uid', userId);
    const isSet = localStorage.getItem('aid');

    // non rootadmins can only override if they have access to the account
    const setAidOverride =
      aidOverride && (c3dUser.accounts.includes(aidOverride) || rootAdmin);
    if (setAidOverride || !isSet || (isSet && !rootAdmin)) {
      // only rootadmicns can be on an account they aren't a part of
      localStorage.setItem(
        'aid',
        setAidOverride ? aidOverride : mainUserAccount
      );
    }
    const tourAccountId = localStorage.getItem('aid');
    batch(async () => {
      try {
        if (rootAdmin) {
          dispatch(setRootAdmin());
        }
        dispatch(saveUserCreds(c3dUser));
        await dispatch(fetchUserPermissions());
        await dispatch(fetchTourAccountInfo());
        dispatch(savePolicyStatus(c3dUser.privacyPolicy)); // await the api call so when it errors we do not make more api calls
        dispatch(getTours(tourAccountId));
        dispatch(getMedia(true));
        dispatch(pollSessionData());
      } catch (error) {
        if (error.message === 'Expired') {
          dispatch(fetchTourAccountError('Account expired'));
        } else if (error.message === 'Unauthorized') {
          dispatch(fetchTourAccountError('Unauthorized'))
        } else if (error.message === 'Locked') {
          dispatch(fetchTourAccountError('Locked'))
        } else throw error;
      }
    });
  };
};

export const handleTourAccountSwap = (tourAccountId, callback) => {
  return async dispatch => {
    try {
      dispatch(fetchTourAccountError(''));
      const tourAccount = await fetchTourAccountFromC3D(tourAccountId);
      if (tourAccount) {
        if (tourAccount.aid) {
          localStorage.setItem('aid', tourAccount.aid);
          batch(() => {
            dispatch(saveAccountInfo(tourAccount));
            dispatch(fetchUserPermissions());
            dispatch(getTours(tourAccount.aid, true));
            dispatch(getMedia(true));
            dispatch(pollSessionData());
            dispatch(resetAccountUserData());
          });
          //set current aid
          typeof callback === 'function' && callback();
        }
      } else {
        dispatch(tourAccountSwapError());
      }
    } catch (e) {
      if (e.message === 'Expired') {
        dispatch(fetchTourAccountError('Account expired'));
      } else if (e.message === 'Unauthorized') {
        dispatch(fetchTourAccountError('Unauthorized'))
      } else if (e.message === 'Locked') {
        dispatch(fetchTourAccountError('Locked'))
      } else dispatch(tourAccountSwapError());
    }
  };
};

export const updateUserPrivacyPolicy = userData => {
  return async dispatch => {
    const data = await updateC3DUserPrivacyPolicy(userData);
    if (data) {
      batch(() => {
        dispatch(savePolicyStatus(data.privacyPolicy));
      });
    }
  };
};

export const fetchTourAccountInfo = () => {
  return async dispatch => {
    const aid = localStorage.getItem('aid');
    const newTourAccount = await fetchTourAccountFromC3D(aid);
    await dispatch(saveAccountInfo(newTourAccount)); //saveUserInfo
  };
};

export const fetchC3DUser = uid => {
  return async dispatch => {
    const c3dUser = await fetchUserFromC3D(uid); //change to user
    dispatch(saveUserCreds(c3dUser));
  };
};

export const fetchUserPermissions = () => {
  return async dispatch => {
    const roles = await getUserRolePermissions();
    // save roles specific to an account
    const { permissions } = roles[localStorage.getItem('aid')] || {};
    if (permissions) {
      dispatch(saveUserPermissions(permissions));
    }
  };
};

export const tourAccountSwapError = () => {
  return {
    type: 'TOUR_ACCOUNT_SWAP_ERROR',
    value: true,
  };
};

export const hideTourAccountSwapError = () => {
  return {
    type: 'TOUR_ACCOUNT_SWAP_ERROR',
    value: false,
  };
};

export const savePolicyStatus = privacyPolicy => {
  return {
    type: 'SAVE_POLICY_STATUS',
    privacyPolicy,
  };
};

export const saveUserCreds = data => {
  return {
    type: 'SAVE_USER_CREDS',
    data,
  };
};

export const setRootAdmin = () => {
  return {
    type: 'SET_ROOT_ADMIN',
  };
};

export const saveUserPermissions = (permissions = {}) => {
  return {
    type: 'SET_USER_PERMISSIONS',
    permissions,
  };
};

export const fetchTourAccountError = error => {
  return {
    type: 'FETCH_TOUR_ACCOUNT_ERROR',
    errorMessage: error,
  };
};
