export const convertObjectToFormData = (object, type) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(
      key,
      key === 'file' || typeof object[key] === 'string'
        ? object[key]
        : JSON.stringify(object[key])
    );
    return formData;
  }, new FormData());
