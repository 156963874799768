const initialState = {
  error: false,
  errorMessage: '',
};

const translations = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_TB_ERROR': {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };
    }
    case 'CLEAR_ERRORS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default translations;
