import { useEffect } from 'react';

export const useIframeEngageListener = ({
  onEngage = () => {},
  attachListener,
}) => {
  useEffect(() => {
    const gaEventOnIframeEnter = e => {
      if (document.activeElement === document.querySelector('iframe')) {
        onEngage();
      }
    };
    if (attachListener) {
      window.addEventListener('blur', gaEventOnIframeEnter);
    }
    return () => {
      attachListener &&
        window.removeEventListener('blur', gaEventOnIframeEnter);
    };
  }, [onEngage, attachListener]);
};
