import merge from 'lodash.merge';

export const initialState = {
  availableLanguages: [],
  tourTranslations: {},
  activeTranslation: {},
  publishedTourLanguages: [],
  status: 'ok',
  csvStatus: 'ok',
  error: {},
};

const translations = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_AVAILABLE_LANGUAGES': {
      return {
        ...state,
        availableLanguages: action.languages,
      };
    }
    case 'SET_TOUR_TRANSLATIONS': {
      return {
        ...state,
        tourTranslations: action.translations,
      };
    }
    case 'SET_ACTIVE_TRANSLATION': {
      return {
        ...state,
        activeTranslation: action.translation,
      };
    }
    case 'SET_PUBLIC_TOUR_LANGUAGES': {
      return {
        ...state,
        publishedTourLanguages: action.languages,
      };
    }
    case 'UPDATE_TOUR_TRANSLATIONS': {
      return {
        ...state,
        tourTranslations: {
          ...merge(state.tourTranslations, action.translations),
        },
      };
    }
    case 'UPDATE_ACTIVE_TRANSLATION': {
      return {
        ...state,
        activeTranslation: {
          ...merge(state.activeTranslation, action.translation),
        },
      };
    }
    case 'TRANSLATIONS_CSV_STATUS': {
      return {
        ...state,
        csvStatus: action.status,
      };
    }
    case 'TRANSLATIONS_STATUS': {
      return {
        ...state,
        status: action.status,
        errorMessage: action.errorMessage,
      };
    }
    case 'TRANSLATION_SUCCESS':
      return {
        ...state,
        state: 'ok',
        error: {},
      };
    default:
      return state;
  }
};

export default translations;
