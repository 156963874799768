import { IDENTITYPOOL, S3BASEBUCKET } from '../constants';
const AWS = require('aws-sdk');
const S3 = require('aws-sdk/clients/s3');

// Configure the credentials provider to use your identity pool
AWS.config.region = 'us-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: IDENTITYPOOL,
});

const rootFolder = '360tours';

export const buildParams = ({
  id = '',
  file = {},
  mediaType = '',
  useEnv = false,
  _bucket = '',
}) => {
  const aid = localStorage.getItem('aid');
  const bucket = _bucket || S3BASEBUCKET;
  const path = useEnv
    ? `${rootFolder}/${process.env.REACT_APP_API_ENV}/${aid}/${mediaType}`
    : mediaType;
  return {
    Bucket: `${bucket}/${path}`,
    Key: `${id}`,
    Body: file,
    ContentType: `${file.type}`,
  };
};

export const s3Upload = async params => {
  const s3 = new S3();
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
  });
};
