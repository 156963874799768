import { _sendHubspotEvent } from '../api/v2/hubspot';

export const sendHubspotEvent = event => {
  return async dispatch => {
    dispatch(submitHubspotEvent());
    try {
      await _sendHubspotEvent(event);
      dispatch(onHubspotSuccess());
    } catch (error) {
      dispatch(onHubspotError(error));
    }
  };
};

export const submitHubspotEvent = () => {
  return {
    type: 'SUBMIT_HUBSPOT_EVENT',
  };
};

export const onHubspotError = error => {
  return {
    type: 'SUBMIT_HUBSPOT_ERROR',
    error,
  };
};

export const onHubspotSuccess = () => {
  return {
    type: 'SUBMIT_HUBSPOT_SUCCESS',
  };
};
